import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardText from "components/Card/CardText.jsx";
import CardBody from "components/Card/CardBody.jsx";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import Autosuggest from "react-autosuggest";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import customSelectStyle from "assets/jss/restart-energy/customSelectStyle.jsx";
import customCheckboxRadioSwitch from "assets/jss/restart-energy/customCheckboxRadioSwitch.jsx";
import deburr from "lodash/deburr";

import DatePicker from 'react-mobile-datepicker';


import * as utils from "helper/utils.js";

//const style = {
//  cardText: {
//    width: "100%",
//    textAlign: "center"
//  },
//  infoText: {
//    fontWeight: "300",
//    margin: "10px 0 30px",
//    textAlign: "center"
//  },
//  inputAdornmentIcon: {
//    color: "#555"
//  },
//  inputAdornment: {
//    position: "relative"
//  }
//};

const style = theme => ({
  cardText: {
    width: "100%",
    textAlign: "center"
  },
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  inputAdornment: {
    position: "relative"
  },
  choiche: {
    textAlign: "center",
    cursor: "pointer",
    marginTop: "20px"
  },
  container: {
    position: "relative"
  },
  suggestionsContainerOpen: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0
  },
  suggestion: {
    display: "block"
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: "none"
  },
  divider: {
    height: theme.spacing.unit * 2
  },
...customSelectStyle,
...withStyles,
...customCheckboxRadioSwitch
});

const monthMap = {
  '1': 'Ian',
  '2': 'Feb',
  '3': 'Mar',
  '4': 'Apr',
  '5': 'Mai',
  '6': 'Iun',
  '7': 'Iul',
  '8': 'Aug',
  '9': 'Sep',
  '10': 'Oct',
  '11': 'Nov',
  '12': 'Dec',
};

const dateConfig = {
  'year': {
    format: 'YYYY',
    caption: 'An',
    step: 1,
  },
  'month': {
    format: value => monthMap[value.getMonth() + 1],
    caption: 'Luna',
    step: 1,
  },
  'date': {
    format: 'DD',
    caption: 'Zi',
    step: 1,
  },
};

let judetFilterDate = [];
let localitatiFilterDate = [];
let straziFilterDate = [];

function renderInputComponent(inputProps) {
  const {
      classes, inputRef = () => {
  }, ref, ...other
} = inputProps;
return (
    <TextField
fullWidth
InputProps={{
  inputRef: node => {
    ref(node);
    inputRef(node);
  },
      classes: {
    input: inputProps.clases.input
  }
}}
{...other}
/>
);
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  const matches = match(suggestion.name, query);
  const parts = parse(suggestion.name, matches);

    if (typeof suggestion.addNew !== 'undefined' && suggestion.addNew[0].isAddNew) {
        return (
            <MenuItem component="div">
                <div>
                    <span style={{ fontWeight: 500 }}>
                       [+] Adauga strada noua: {suggestion.addNew[1].value}
                    </span>
                </div>
            </MenuItem>
        );
    }

  return (
      <MenuItem selected={isHighlighted} component="div">
  <div>
  {parts.map((part, index) =>
      part.highlight ? (
      <span key={String(index)} style={{ fontWeight: 500 }}>
{part.text}
</span>
) : (
<strong key={String(index)} style={{ fontWeight: 300 }}>
{part.text}
</strong>
)
)}
</div>
</MenuItem>
);
}

function getSuggestionValue(suggestion) {
  //this.wizardManger.setDataValue("location", suggestion);
  //this.state.locationState = "success";
    if (typeof suggestion.addNew !== 'undefined' && suggestion.addNew[0].isAddNew) {
        return suggestion.addNew[1].value;
    }

  return suggestion.name;
}

function getSuggestionsJudet(value) {
  let that = this;
  const inputValue = deburr(value.trim()).toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;
  return inputLength === 0
      ? []
      : judetFilterDate.filter(suggestion => {
    const keep =
        count < 5 && suggestion.name.slice(0, inputLength).toLowerCase() === inputValue;

    if (keep) {
      count += 1;
    }
    return keep;
  });
}
function getSuggestionsLocalitati(value) {
  let that = this;
  const inputValue = deburr(value.trim()).toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;
  return inputLength === 0
      ? []
      : localitatiFilterDate.filter(suggestion => {
    const keep =
        count < 5 && suggestion.name.slice(0, inputLength).toLowerCase() === inputValue;

    if (keep) {
      count += 1;
    }
    return keep;
  });
}

function getSuggestionsStrazi(value) {
  let that = this;
  const inputValue = deburr(value.trim()).toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;

    const suggestions = straziFilterDate.filter(suggestion => {
        const keep =
            count < 5 && suggestion.name.slice(0, inputLength).toLowerCase() === inputValue;

        if (keep) {
            count += 1;
        }
        return keep;
    });

    return inputLength === 0
      ? []
      : (suggestions.length === 0 ? [{addNew: [{isAddNew: true}, {value: value.trim()}, {inputName:  'c_strada'}]}] : suggestions);
}

class StepDetaliiContractualeData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cale_transmitere_factura: "",
      cale_transmitere_factura_e: "",
      cale_transmitere_factura_eState: "",
      cale_transmitere_factura_p: "",
      cale_transmitere_factura_pState: "",
      cale_transmitere_factura_ev: "",
      cale_transmitere_factura_pv: "",
      adresa_corespondenta: "",
      adresa_corespondentaState: "",
      c_tara: "Romania",
      c_judet: "",
      singleJudetId:"",
      c_judetState: "",
      c_localitate: "",
      singleLocalitatiId: "",
      c_localitateState: "",
      c_strada: "",
      singleStraziId:"",
      c_stradaState: "",
      c_nr: "",
      c_sector: "",
      c_bloc: "",
      c_scara: "",
      c_tronson: "",
      c_etaj: "",
      c_apartament: "",
      c_cod_postal: "",
      data_semnare_contract: new Date(),
      data_semnare_contractState: "",
      isOpen: false,
      isOpenEnd: false,
      isOpenStart: false,
      isLoaded:false,
      options : { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' },
      folosesteAdresaDomiciliu: "",
      folosesteAdresaLocConsum: "",
      folosesteAltaAdresa: "",
      folosesteAdresaDomiciliuState: "",
      folosesteAdresaLocConsumState: "",
      folosesteAltaAdresaState: "",
      showAdressFields: false,
      suggestions: [],
      suggestionsJudet: [],
      suggestionsLocalitati: [],
      suggestionsStrazi: [],
      unlockLocalitati: true,
      unlockStrazi: true,
      utils: utils.utils()
    };

    this.handleClickStart = this.handleClickStart.bind(this);
    this.handleClickEnd = this.handleClickEnd.bind(this);

    this.handleSelectEnd = this.handleSelectEnd.bind(this);

    this.handleCancelStart = this.handleCancelStart.bind(this);
    this.handleCancelEnd = this.handleCancelEnd.bind(this);

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSimpleInputChangeE = this.handleSimpleInputChangeE.bind(this);
    this.handleSimpleInputChangeP = this.handleSimpleInputChangeP.bind(this);

    this.wizardManger = window.wizardManager;

  }

  loadJudeteData() {
    let that = this;

    judetFilterDate = [];

    if (this.wizardManger.wizardData.step.stepId === "detalii_contractuale_data" &&
        typeof this.wizardManger.wizardData.type !== "undefined" && this.wizardManger.wizardData.type) {
      this.wizardManger.loadJudeteData().then(function(data) {

        that.loadingJudetData = false;
        that.setState({ judetData: data.judetList });
        for(var i=0; i<data.judetList.length; i++){
          judetFilterDate.push(data.judetList[i]);
        }
      })
          .catch(function(err) {
            that.loadingJudetData = false;
            alert(err);
          });
    }

    that.loadingJudetData = false;
  }

  loadLocalitatiData() {
    let that = this;

    localitatiFilterDate = [];

    if (this.wizardManger.wizardData.step.stepId === "detalii_contractuale_data" &&
        typeof this.wizardManger.wizardData.type !== "undefined" && this.wizardManger.wizardData.type) {
      this.wizardManger.loadLocalitatiData(this.state.singleJudetId).then(function(data) {

        that.loadingLocalitatiData = false;
        that.setState({ localitatiData: data.localitateList });
        for(var i=0; i<data.localitateList.length; i++){
          localitatiFilterDate.push(data.localitateList[i]);
        }
      })
          .catch(function(err) {
            that.loadingLocalitatiData = false;
            alert(err);
          });
    }

    that.loadingLocalitatiData = false;
  }

  loadStraziData() {
    let that = this;

    straziFilterDate = [];

    if (this.wizardManger.wizardData.step.stepId === "detalii_contractuale_data" &&
        typeof this.wizardManger.wizardData.type !== "undefined" && this.wizardManger.wizardData.type) {
      this.wizardManger.loadStraziData(this.state.singleLocalitatiId).then(function(data) {

        that.loadingStraziData = false;
        that.setState({ straziData: data.straziList });
        for(var i=0; i<data.straziList.length; i++){
          straziFilterDate.push(data.straziList[i]);
        }
      })
          .catch(function(err) {
            that.loadingStraziData = false;
            alert(err);
          });
    }

    that.loadingStraziData = false;
  }

  mandatory(value) {
    return value !== "" && value !== null ? true : false;
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if (name =="folosesteAdresaDomiciliu"){
      this.applyNewAdress("d_",window.wizardManager.wizardData.customer,"c_");
      this.setState({
        ["folosesteAdresaLocConsum"]: false,
        ["folosesteAltaAdresa"]: false
      });
      this.state.folosesteAdresaDomiciliuState = value == true ? "success":"error";
      this.state.adresa_corespondentaState = value == true ? "success":"error";
    }

    if (name =="folosesteAdresaLocConsum"){
      this.applyNewAdress("l_",window.wizardManager.wizardData.locConsum,"c_");
      this.setState({
        ["folosesteAdresaDomiciliu"]: false,
            ["folosesteAltaAdresa"]: false
      });
      this.state.folosesteAdresaLocConsumState = value == true ? "success":"error";
        this.state.adresa_corespondentaState = value == true ? "success":"error";
    }
    if (name == "folosesteAltaAdresa"){
      this.setState({
        ["folosesteAdresaDomiciliu"]: false,
        ["folosesteAdresaLocConsum"]: false
      });
      this.state.folosesteAltaAdresaState = value == true ? "success":"error";
        this.state.adresa_corespondentaState = value == true ? "success":"error";
    }
    this.setState({
      [name]: value
    });
    this.state.showAdressFields = false;
    if (value == true && name == "folosesteAltaAdresa"){
      this.resetAdress("c_");
      this.state.c_tara="Romania";
      this.state.showAdressFields = true;
        this.state.adresa_corespondentaState = value == true ? "success":"error";
    }
  }

  setCaleTrans(){

    let le = this.state.cale_transmitere_factura_ev != null ? this.state.cale_transmitere_factura_ev.length : 0;
        let lp = this.state.cale_transmitere_factura_pv != null ? this.state.cale_transmitere_factura_pv.length : 0;

    if (le > 0 && lp > 0) {
      this.state.cale_transmitere_factura = this.state.cale_transmitere_factura_ev + " si " +this.state.cale_transmitere_factura_pv;
      return;
    }
    if (le > 0) {
      this.state.cale_transmitere_factura = this.state.cale_transmitere_factura_ev;
      return;
    }
    if (lp > 0) {
      this.state.cale_transmitere_factura = this.state.cale_transmitere_factura_pv;
      return;
    }
    this.state.cale_transmitere_factura="";

  }


  handleSimpleInputChangeE(event) {

    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
      value == true ? this.state.cale_transmitere_factura_ev = 'Email (Gratuit)' : this.state.cale_transmitere_factura_ev = '';
    if (value == true) {
      this.state.cale_transmitere_factura_eState = "success";
    } else {
      this.state.cale_transmitere_factura_eState = "error";
    }
  }

  handleSimpleInputChangeP(event) {

    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
    value == true ? window.wizardManager.wizardData.subscriptionType == "fix" ? this.state.cale_transmitere_factura_pv = 'Posta (taxat suplimentar pentru serviciul postal asigurat de Compania Nationala Posta Romana S.A.)' : this.state.cale_transmitere_factura_pv = 'Posta (Gratuit)' : this.state.cale_transmitere_factura_pv ='';
    if (value == true) {
      this.state.cale_transmitere_factura_pState = "success";
    } else {
      this.state.cale_transmitere_factura_pState = "error";
    }
  }

  applyNewAdress(vfrom , val, vto){
    this.state.adresa_corespondenta="";
    this.state[vto+"tara"] = val[vfrom+"tara"];
    this.state[vto+"judet"] = val[vfrom+"judet"];
    this.state[vto+"localitate"] = val[vfrom+"localitate"];
    this.state[vto+"strada"] = val[vfrom+"strada"];
    this.state[vto+"nr"] = val[vfrom+"nr"];
    this.state[vto+"sector"] = val[vfrom+"sector"];
    this.state[vto+"bloc"] = val[vfrom+"bloc"];
    this.state[vto+"scara"] = val[vfrom+"scara"];
    this.state[vto+"tronson"] = val[vfrom+"tronson"];
    this.state[vto+"etaj"] = val[vfrom+"etaj"];
    this.state[vto+"apartament"] = val[vfrom+"apartament"];
    this.state[vto+"cod_postal"] = val[vfrom+"cod_postal"];
      this.state["singleLocalitatiId"] = val["singleLocalitatiId"];
      this.state["singleStraziId"] = val["singleStraziId"];
      this.state["singleJudetId"] = val["singleJudetId"];
    this.setAdress();
  }
  resetAdress(vto){
    this.state.adresa_corespondenta="";
    this.state[vto+"tara"] = "";
    this.state[vto+"judet"] = "";
    this.state[vto+"localitate"] = "";
    this.state[vto+"strada"] = "";
    this.state[vto+"nr"] = "";
    this.state[vto+"sector"] = "";
    this.state[vto+"bloc"] = "";
    this.state[vto+"scara"] = "";
    this.state[vto+"tronson"] = "";
    this.state[vto+"etaj"] = "";
    this.state[vto+"apartament"] = "";
    this.state[vto+"cod_postal"] = "";
      this.state["singleLocalitatiId"] = "";
      this.state["singleStraziId"] = "";
      this.state["singleJudetId"] = "";
  }

  handleClickStart = event => {
    event.preventDefault();
    this.setState({ isOpenStart: true });
    this.state.isLoaded=true;
  }
  handleClickEnd = event => {
    event.preventDefault();
    this.setState({ isOpenEnd: true });
  }

  handleSelectEnd = (data_semnare_contract) => {
    this.setState({ data_semnare_contract, isOpenEnd: false,data_semnare_contractState:"success" });
  }

  handleCancelStart = () => {
    this.setState({ isOpenStart: false });
  }
  handleCancelEnd = () => {
    this.setState({ isOpenEnd: false });
  }

  sendState() {
    return this.state;
  }
  // function that returns true if value is email, false otherwise
  verifyEmail(value) {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }

  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }
  change(event, stateName, type, stateNameEqualTo) {
    this.setState({ [stateName]: event.target.value });
    switch (type) {
      case "length":
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "mandatory":
        if (this.mandatory(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      default:
        break;
    }
  }

  reloadState() {
    this.state.cale_transmitere_factura="";
    this.wizardManger.setDataValue("detaliiContractuale", null);
    this.setState({ detaliiContractuale: "", detaliiContractualeData: null });
  }

  setAdress(){
    this.state.adresa_corespondenta = "";
    this.checkNullAndAdd("Tara", this.state.c_tara);
    this.checkNullAndAdd("Judet", this.state.c_judet);
    this.checkNullAndAdd("Localitate", this.state.c_localitate);
    this.checkNullAndAdd("Str.", this.state.c_strada);
    this.checkNullAndAdd("Nr.",this.state.c_nr);
    this.checkNullAndAdd("Sec.",this.state.c_sector);
    this.checkNullAndAdd("Bl.",this.state.c_bloc);
    this.checkNullAndAdd("Sc.",this.state.c_scara);
    this.checkNullAndAdd("Trons.",this.state.c_tronson);
    this.checkNullAndAdd("Et.",this.state.c_etaj);
    this.checkNullAndAdd("Ap.",this.state.c_apartament);
    this.checkNullAndAdd("Cod pos.",this.state.c_cod_postal);
  }

  checkNullAndAdd(name, val){
    if (val !== "" && val !== null){
      if (!this.state.adresa_corespondenta.includes(",") && Object.keys(this.state.adresa_corespondenta).length == 0){
        this.state.adresa_corespondenta = name + " " + val;
      } else {
        this.state.adresa_corespondenta = this.state.adresa_corespondenta + ", " + name + " " + val;
      }
    }
  }

  checkValidAdress(){
    if (this.state.showAdressFields){
      let ret = this.state.c_judetState === "success" && this.state.c_localitateState === "success" &&  this.state.c_stradaState === "success";
      if (ret){
        if (this.state.folosesteAltaAdresaState !== "success"){
          this.setState({folosesteAltaAdresaState: "error"});
        } else {
          this.setState({folosesteAltaAdresaState: "success"});
        }
        return true;
      }
      return false;
    } else {
      if (this.state.folosesteAdresaDomiciliuState == "success" || this.state.folosesteAdresaLocConsumState == "success") {
        return true;
      } else {
      if (this.state.folosesteAdresaDomiciliuState !== "success") {
        this.setState({folosesteAdresaDomiciliuState: "error"});
      } else {
        this.setState({folosesteAdresaDomiciliuState: "success"});
      }
      if (this.state.folosesteAdresaLocConsumState !== "success") {
        this.setState({folosesteAdresaLocConsumState: "error"});
      } else {
        this.setState({folosesteAdresaLocConsumState: "success"});
      }
    }
    }
    return false;
  }

    checkEmail(){
        let that = this;
    if (this.state.cale_transmitere_factura_e && window.wizardManager.wizardData.customer.email.length == 0){
        that.errMsg = '';
        that.errMsg = 'Aveti bifa de mail dar mail-ul nu este completat!';
        return false;
    }
    return true;
    }

    retrySetAdress(){

        if (this.state.folosesteAdresaDomiciliu){
            this.applyNewAdress("d_",window.wizardManager.wizardData.customer,"c_");
        }
        if (this.state.folosesteAdresaLocConsum){
            this.applyNewAdress("l_",window.wizardManager.wizardData.locConsum,"c_");
        }
        if (this.state.folosesteAltaAdresa){

        }
    }

  isValidated() {
    let that = this;
    that.errMsg = '';
    let today = new Date();
    let data_semnare_contract_check = this.state.data_semnare_contract;
    this.setAdress();
    if (this.checkEmail() && (this.state.cale_transmitere_factura_eState == "success" || (this.state.cale_transmitere_factura_pState == "success" && this.checkValidAdress())) && (data_semnare_contract_check.toDateString() == today.toDateString() && !isNaN(data_semnare_contract_check))) {
      this.setCaleTrans();
      this.retrySetAdress();
      this.wizardManger.setDataValue("detaliiContractuale", this.state);
      return true;
    } else {
      if (this.state.cale_transmitere_factura_eState !== "success" ){
        this.setState({ cale_transmitere_factura_eState: "error" });
      }
      if (this.state.cale_transmitere_factura_pState !== "success" ){
        this.setState({ cale_transmitere_factura_pState: "error" });
      }
      if (data_semnare_contract_check.toDateString() != today.toDateString()  || isNaN(data_semnare_contract_check)){
          this.setState({ data_semnare_contractState: "error" });
          that.errMsg = 'Data semnare contract trebuie sa fie azi!';
          return false;
      } else {
          this.setState({ data_semnare_contractState: "success" });
      }
        if (this.state.adresa_corespondentaState !== "success"){
            this.setState({ adresa_corespondentaState: "error" });
        } else {
            this.setState({ adresa_corespondentaState: "success" });
        }
        if (this.state.c_judetState !== "success") {
          this.setState({c_judetState: "error"});
        }
        if (this.state.c_localitateState !== "success") {
          this.setState({c_localitateState: "error"});
        }
        if (this.state.c_stradaState !== "success") {
          this.setState({c_stradaState: "error"});
        }
      return false;
    }
  }

  handleSuggestionsFetchRequestedJudet = ({ value }) => {
    this.setState({
      suggestionsJudet: getSuggestionsJudet(value),
    });
  }

  handleSuggestionsClearRequestedJudet = () => {
    this.setState({
      suggestionsJudet: []
    });
  }

  handleSuggestionsFetchRequestedLocalitati = ({ value }) => {
    this.setState({
      suggestionsLocalitati: getSuggestionsLocalitati(value),
    });
  }

  handleSuggestionsClearRequestedLocalitati = () => {
    this.setState({
      suggestionsLocalitati: []
    });
  }

  handleSuggestionsFetchRequestedStrazi = ({ value }) => {
    this.setState({
      suggestionsStrazi: getSuggestionsStrazi(value),
    });
  }

  handleSuggestionsClearRequestedStrazi = () => {
    this.setState({
      suggestionsStrazi: []
    });
  }

    onSuggestionSelectedStrazi = (event, { suggestion }) => {
        if (typeof suggestion.addNew !== 'undefined' && suggestion.addNew[0].isAddNew) {
            this.state[suggestion.addNew[2].inputName + "State"] = "success";
            this.state.c_cod_postalState = "error";
            this.state.c_cod_postal = "";
            //console.log('Add new:', suggestion.addNew[1].value);
        }
    };

  handleChange = name => (event, { newValue }) => {

    this.setState({
      [name]: newValue
    });
    if (judetFilterDate != "undefined" && Object.keys(judetFilterDate).length > 0 && name == "c_judet") {
      this.setState({
        [name]: newValue
      });
      var bol = false;
      var lngth = Object.keys(judetFilterDate).length;
      var i = 0;
      this.state.unlockLocalitati = true;
      this.state.unlockStrazi = true;
      this.state.c_localitate = "";
      this.state.c_strada = "";
      this.state.singleLocalitatiId = "";
        this.state.singleStraziId = "";
      for (i = 0; i < lngth; i++) {
        if (judetFilterDate[i].name == newValue) {
          bol = true;
          this.state.unlockLocalitati = false;
          this.state.singleJudetId = judetFilterDate[i].countyId;
          this.loadLocalitatiData();
        }
      }
      if (bol) {
        this.setState({[name + "State"]: "success"});
      } else {
        this.setState({[name + "State"]: "error"});
      }
      return;
    }
    if (localitatiFilterDate != "undefined" && localitatiFilterDate.length > 0 && name == "c_localitate") {
      this.setState({
        [name]: newValue
      });
      let bol = false;
      let lngth = localitatiFilterDate.length;
      let i = 0;
      this.state.unlockStrazi = true;
      this.state.c_strada = "";
      this.state.singleLocalitatiId = "";
        this.state.singleStraziId = "";
      for (i = 0; i < lngth; i++) {
        if (localitatiFilterDate[i].name == newValue) {
          this.state.unlockStrazi = false;
          this.state.singleLocalitatiId = localitatiFilterDate[i].localityId;
          this.loadStraziData();
          bol = true;
        }
      }
      if (bol) {
        this.setState({[name + "State"]: "success"});
      } else {
        this.setState({[name + "State"]: "error"});
      }
      return;
    }

    if (straziFilterDate != "undefined" && straziFilterDate.length > 0 && name == "c_strada") {
      this.setState({
        [name]: newValue
      });
      let bol = false;
      let lngth = straziFilterDate.length;
      let i = 0;
        this.state.singleStraziId = "";
      for (i = 0; i < lngth; i++) {
        if (straziFilterDate[i].name == newValue) {
          bol = true;

            this.state.singleStraziId = straziFilterDate[i].streetId;
            this.state.c_cod_postal = straziFilterDate[i].zipCode;
        }
      }
      if (bol) {
        this.setState({[name + "State"]: "success"});
      } else {
        this.setState({[name + "State"]: "error"});
      }
      return;
    }
  };

  render() {

    if (!this.state.judetData) {
      if (!this.loadingJudetData) {
        this.loadingJudetData = true;
        this.loadJudeteData();
      }

      return <div>Loading...</div>;
    }

    let that = this;
    const { classes } = this.props;
    return (
      <GridContainer justify="center">
        <Card>
          <CardHeader color="warning" text>
            <CardText className={classes.cardText} color="danger">
              <h4 style={{ width: "100%" }} className={classes.cardTitleWhite}>
                Detalii contractuale
              </h4>
              <h4 className={classes.cardCategoryWhite}>
<i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
<i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
<i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
<i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
<i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
<i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
<i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
<i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,1)"}} className="fas fa-circle"></i>
<i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
                                <i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
              </h4>
            </CardText>
          </CardHeader>
          <CardBody>
            <GridItem xs={12} sm={12} md={12} lg={12}>

                            <CustomInput
                  success={this.state.cale_transmitere_factura_eState === "success" || this.state.cale_transmitere_factura_pState === "success"}
                  error={this.state.cale_transmitere_factura_eState === "error" && this.state.cale_transmitere_factura_pState === "error"}
                  labelText={<span>Alegeti calea de transmitere:</span>}
                  inputProps={{
                      disabled: "true",
                  }}
                  formControlProps={{
                      fullWidth: true
                  }}
              />
<br/>
<br/>
<input
success={this.state.cale_transmitere_factura_eState === "success"}
error={this.state.cale_transmitere_factura_eState === "error"}
name="cale_transmitere_factura_e"
type="checkbox"
checked={this.state.cale_transmitere_factura_e}
onChange={this.handleSimpleInputChangeE}
/>
<label>Email (Gratuit) </label>
<br/>
<br/>
<input
success={this.state.cale_transmitere_factura_pState === "success"}
error={this.state.cale_transmitere_factura_pState === "error"}
name="cale_transmitere_factura_p"
type="checkbox"
checked={this.state.cale_transmitere_factura_p}
onChange={this.handleSimpleInputChangeP}
/>
<label>Posta {window.wizardManager.wizardData.type == "electricity" ? "(taxat suplimentar pentru serviciul postal asigurat de Compania Nationala Posta Romana S.A.)" : "(Gratuit)"}</label>

              <CustomInput
                  success={this.state.adresa_corespondentaState === "success"}
                  error={this.state.adresa_corespondentaState === "error"}
                  labelText={<span>Adresa de corespondenta:</span>}
                  inputProps={{
                      disabled: "true",
                  }}
                  formControlProps={{
                      fullWidth: true
                  }}
              />
                <br/>
                <br/>
                <input
                name="folosesteAdresaDomiciliu"
                type="checkbox"
                checked={this.state.folosesteAdresaDomiciliu}
                onChange={this.handleInputChange}
                />
                <label>Folositi adresa de domiciliu</label>

                <br/>
                <br/>
                <input
                name="folosesteAdresaLocConsum"
                type="checkbox"
                checked={this.state.folosesteAdresaLocConsum}
                onChange={this.handleInputChange}
                />
                <label>Folositi adresa locului de consum</label>
<br/>
<br/>
<input
name="folosesteAltaAdresa"
type="checkbox"
checked={this.state.folosesteAltaAdresa}
onChange={this.handleInputChange}
/>
<label>Introduceti alta adresa:</label>

              <DatePicker
                headerFormat="DD/MM/YYYY"
                isPopup={true}
                dateConfig={dateConfig}
                showCaption={true}
                confirmText="Selecteaza"
                cancelText="Anuleaza"
                value={this.state.data_semnare_contract}
                isOpen={this.state.isOpenEnd}
                onSelect={this.handleSelectEnd}
                onCancel={this.handleCancelEnd} />
              <CustomInput
                success={this.state.data_semnare_contractState === "success"}
                error={this.state.data_semnare_contractState === "error"}
                labelText={<span> Data semnare contract</span>}
                id="data_semnare_contract"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value:this.state.data_semnare_contract.toLocaleDateString("ro-RO", this.state.options),
                  onClick: event => this.handleClickEnd(event),
                  onKeyup: event => function (event) {
                      event.preventDefault();
                  }
                }}
              />
            {togleAdressFields(this, this.state.showAdressFields, classes, that)}
            </GridItem>
          </CardBody>
        </Card>
      </GridContainer>
    );
  }
}
export default withStyles(style)(StepDetaliiContractualeData);

function togleAdressFields(tht, val, clases, tat){

  const autosuggestPropsJudet = {
    renderInputComponent,
    suggestions: tht.state.suggestionsJudet,
    onSuggestionsFetchRequested: tht.handleSuggestionsFetchRequestedJudet,
    onSuggestionsClearRequested: tht.handleSuggestionsClearRequestedJudet,
    getSuggestionValue,
    utils: utils.utils(),
    wizardManger: window.wizardManager,
    state: tht.state,
    renderSuggestion
  };

  const autosuggestPropsLocalitati = {
    renderInputComponent,
    suggestions: tht.state.suggestionsLocalitati,
    onSuggestionsFetchRequested: tht.handleSuggestionsFetchRequestedLocalitati,
    onSuggestionsClearRequested: tht.handleSuggestionsClearRequestedLocalitati,
    getSuggestionValue,
    utils: utils.utils(),
    wizardManger: window.wizardManager,
    state: tht.state,
    renderSuggestion
  };

  const autosuggestPropsStrazi = {
    renderInputComponent,
    suggestions: tht.state.suggestionsStrazi,
    onSuggestionsFetchRequested: tht.handleSuggestionsFetchRequestedStrazi,
    onSuggestionsClearRequested: tht.handleSuggestionsClearRequestedStrazi,
    onSuggestionSelected: tht.onSuggestionSelectedStrazi,
    getSuggestionValue,
    utils: utils.utils(),
    wizardManger: window.wizardManager,
    state: tht.state,
    renderSuggestion
  };


  if (val){
    return <div><CustomInput
    labelText={<span>Tara corespondenta</span>}
  id="c_tara"
  formControlProps={{
    fullWidth: true
  }}
inputProps={{
  value: tht.state.c_tara,
  onChange: event =>
      tht.change(event, "c_tara", "length", 400)
}}
/>
<Autosuggest
{...autosuggestPropsJudet}
inputProps={{
  clases,
      label: 'Judet corespondenta (obligatoriu)',
      placeholder: 'Introdu primele 3 litere',
    success: tht.state.c_judetState === "success",
    error: tht.state.c_judetState === "error",
      value: tht.state.c_judet,
      onChange: tht.handleChange('c_judet')
}}
theme={{
  container: clases.container,
      suggestionsContainerOpen: clases.suggestionsContainerOpen,
      suggestionsList: clases.suggestionsList,
      suggestion: clases.suggestion,
}}
renderSuggestionsContainer={options => (<Paper {...options.containerProps} square>{options.children}</Paper>)}
/>
<Autosuggest
{...autosuggestPropsLocalitati}
inputProps={{
  clases,
      disabled: tht.state.unlockLocalitati,
      label: 'Localitate corespondenta (obligatoriu)',
      placeholder: 'Introdu primele 3 litere',
      value: tht.state.c_localitate,
    success: tht.state.c_localitateState === "success",
    error: tht.state.c_localitateState === "error",
      onChange: tht.handleChange('c_localitate')
}}
theme={{
  container: clases.container,
      suggestionsContainerOpen: clases.suggestionsContainerOpen,
      suggestionsList: clases.suggestionsList,
      suggestion: clases.suggestion,
}}
renderSuggestionsContainer={options => (
<Paper {...options.containerProps} square>
{options.children}
</Paper>
)}
/>

<Autosuggest
{...autosuggestPropsStrazi}
inputProps={{
  clases,
      disabled: tht.state.unlockStrazi,
      label: 'Strada corespondenta (obligatoriu)',
      placeholder: 'Introdu primele 3 litere',
      value: tht.state.c_strada,
    success: tht.state.c_stradaState === "success",
    error: tht.state.c_stradaState === "error",
      onChange: tht.handleChange('c_strada')
}}
theme={{
  container: clases.container,
      suggestionsContainerOpen: clases.suggestionsContainerOpen,
      suggestionsList: clases.suggestionsList,
      suggestion: clases.suggestion,
}}
renderSuggestionsContainer={options => (
<Paper {...options.containerProps} square>
{options.children}
</Paper>
)}
/>
<CustomInput
labelText={<span>Nr. corespondenta</span>}
id="c_nr"
formControlProps={{
  fullWidth: true
}}
inputProps={{
  onChange: event =>
      tht.change(event, "c_nr", "length", 400)
}}
/>

<CustomInput
labelText={<span>Bloc corespondenta</span>}
id="c_bloc"
formControlProps={{
  fullWidth: true
}}
inputProps={{
  onChange: event =>
      tht.change(event, "c_bloc", "length", 400)
}}
/>

<CustomInput
labelText={<span>Scara corespondenta</span>}
id="c_scara"
formControlProps={{
  fullWidth: true
}}
inputProps={{
  onChange: event =>
      tht.change(event, "c_scara", "length", 400)
}}
/>

<CustomInput
labelText={<span>Tronson corespondenta</span>}
id="c_tronson"
formControlProps={{
  fullWidth: true
}}
inputProps={{
  onChange: event =>
      tht.change(event, "c_tronson", "length", 400)
}}
/>
<CustomInput
labelText={<span>Etaj corespondenta</span>}
id="c_etaj"
formControlProps={{
  fullWidth: true
}}
inputProps={{
  onChange: event =>
      tht.change(event, "c_etaj", "length", 400)
}}
/>
<CustomInput
labelText={<span>Apartament corespondenta</span>}
id="c_apartament"
formControlProps={{
  fullWidth: true
}}
inputProps={{
  onChange: event =>
      tht.change(event, "c_apartament", "length", 400)
}}
/>
<CustomInput
labelText={<span>Cod postal corespondenta</span>}
id="c_cod_postal"
formControlProps={{
  fullWidth: true
}}
inputProps={{
    value: tht.state.c_cod_postal,
  onChange: event =>
      tht.change(event, "c_cod_postal", "length", 400)
}}
/></div>
  }
return "";
}
