import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { LoginManager } from "data/loginManager";
import { WizardManager } from "data/wizardManager";
import Pages from "layouts/Pages.jsx";

import {
  BrowserRouter,
  Router,
  Route,
  Link,
  Redirect,
  withRouter,
  Switch
} from "react-router-dom";

import indexRoutes from "routes/index.jsx";

import "assets/scss/restart-energy.css?v=1.4.0";

const hist = createBrowserHistory();

window.loginManager = new LoginManager();
window.wizardManager = new WizardManager();

window.utf8_to_b64 = function(str) {
    return window.btoa(unescape(encodeURIComponent( str )));
};

window.b64_to_utf8 = function(str){
    return decodeURIComponent(escape(window.atob( str )));
};

function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        window.loginManager.loadUserDataFromSession().valid ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/auth/login",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
}

ReactDOM.render(
  <BrowserRouter basename={window.BASE_NAME}>
    <Switch>
      <Route path="/auth" component={Pages} />

      {indexRoutes.map((prop, key) => {
        return <PrivateRoute path={prop.path} component={prop.component} key={key} />;
      })}
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
)

