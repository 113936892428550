import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// material-ui icons
import Assignment from "@material-ui/icons/Assignment";
import Sign from "@material-ui/icons/BorderColor";
import Read from "@material-ui/icons/Visibility";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Table from "components/Table/Table.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardText from "components/Card/CardText.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import { SketchField, Tools } from "react-sketch";

// material-ui components
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CustomerSignStyle from "assets/jss/restart-energy/views/extendedTablesStyle.jsx";
import modalStyle from "assets/jss/restart-energy/modalStyle.jsx";
import * as utils from "helper/utils.js";

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

const style = {
  cardText: {
    width: "100%",
    textAlign: "center"
  },
  schetchFieldDiv: {
    overflow:"auto",
    display:"inline-block",
    border: "1px solid blue;",
    width:"220px;"
  },
  ...CustomerSignStyle,
  ...modalStyle
};

class StepCustomerSign extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: [],
      modal: false,
      canvas: [],
      utils: utils.utils()
    };
    this.handleToggle = this.handleToggle.bind(this);

    this.wizardManger = window.wizardManager;
    this.loginManager = window.loginManager;
    this.lastId = null;
  }

  HandleDone = () => {
      if (typeof this.lastId !== 'undefined' && this.lastId !== null) {
          let canvas = this.state.canvas[0];
          let b64 = canvas.toDataURL();

          let signDocumentData = this.state.signDocumentData;

          for (let doc of signDocumentData) {
              if (doc.id === this.lastId) {
                  doc.signature = b64;
                  break;
              }
          }

          this.setState({signDocumentData: signDocumentData});
          this.wizardManger.wizardData.signDocuments = signDocumentData;
      }
      this.handleClose("modal");
  };

  handleToggle(value) {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked
    });
  }

  clearCanvas(){
      let canvas = this.state.canvas[0];
      canvas.clear();
  }

  handleClickOpen(id, modal) {
    this.lastId = id;

    this.setState({
      modal: true
    });


    this.clearCanvas();
  }

  handleClose(modal) {
    this.setState({
      modal: false
    });
  }

  sign() {
    //
  }

  reloadState() {

  }

  getTableData(rb) {
    let that = this;
    let tableData = [];

    let i = 1;
    for (let doc of this.state.signDocumentData) {
      tableData.push([i, doc.numeDocument + " " + that.getDocSignatureStr(doc), that.getButtonData(doc)]);
      i++;
    }

    return tableData;
  }

  isValidated() {
      let that = this;
      that.errMsg = '';

      if (this.state.signDocumentData) {
          for (let doc of this.state.signDocumentData) {
              if (typeof doc.signature === 'undefined' || doc.signature === null || doc.signature === '') {
                  that.errMsg = 'Nu ati semnat toate documentele!';
                  return false;
              }
          }
      }
      return true;
  }

  downloadDoc(id, link) {
      let that = this;
      this.wizardManger
          .getPreviewSignDownloadLink(id)
          .then(function(data) {
              let req = {};
              req.id = id;
              req.name = data.name;
              req.type = 'signature_file';
              req.userData = that.loginManager.userData;

              window.open(that.loginManager.config.previewerUrl + "?data=" + window.utf8_to_b64(JSON.stringify(req)), '_blank');
          })
          .catch(function(err) {
              alert(err.message);
          });
  }

  getButtonData(doc) {
    let that = this;

    let roundButtons = [
            { color: "success", icon: Read, action: () => that.downloadDoc(doc.id, doc.downloadLink)},
            { color: "warning", icon: Sign, action: () => that.handleClickOpen(doc.id, "modal")}
    ].map((prop, key) => {
      return (
        <Button
          round
          color={prop.color}
          className={
            this.props.classes.actionButton +
            " " +
            this.props.classes.actionButtonRound
          }
          key={key}
          onClick={prop.action}
        >
          <prop.icon className={this.props.classes.icon} />
        </Button>
      );
    });

    return roundButtons;
  }

  getDocSignatureStr(doc) {
      let that = this;

      return (typeof doc.signature !== 'undefined' && doc.signature !== null && doc.signature !== '') ? "✔" : "";
  }

  hasTableData() {
        return typeof this.state.signDocumentData !== 'undefined' && this.state.signDocumentData !== null && this.state.signDocumentData.length > 0;
  }

  loadSignDocumentData() {
    let that = this;

        if (this.wizardManger.wizardData.step.stepId === 'customer_sign' &&
            typeof this.wizardManger.wizardData.offer !== 'undefined' && this.wizardManger.wizardData.offer &&
            typeof this.wizardManger.wizardData.offer.idFisier !== 'undefined' && this.wizardManger.wizardData.offer.idFisier){

      this.wizardManger
        .loadSignDocumentData()
        .then(function(data) {
          that.loadingSignDocumentData = false;
          that.setState({ signDocumentData: data.fisierSemnaturaReList });

          that.wizardManger.wizardData.signDocuments =
            data.fisierSemnaturaReList;
        })
        .catch(function(err) {
          that.loadingSignDocumentData = false;
          alert(err);
        });
    }

    that.loadingSignDocumentData = false;
  }

  render() {
    let that = this;
    const { classes } = this.props;

    if (!this.state.signDocumentData) {
      if (!this.loadingSignDocumentData) {
        this.loadingSignDocumentData = true;
        this.loadSignDocumentData();
      }

      return <div>Loading...</div>;
    }

    if (!that.hasTableData()) {
      return (
        <Card>
          <CardHeader color="warning" text>
            <CardText className={classes.cardText} color="danger">
              <h4 style={{ width: "100%" }} className={classes.cardTitleWhite}>
                Semnare
              </h4>
              <h4 className={classes.cardCategoryWhite}>
                                <i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
                                <i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
                                <i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
                                <i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
                                <i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
                                <i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
                                <i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
                                <i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
                                <i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,1)"}} className="fas fa-circle"></i>
                                <i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
              </h4>
            </CardText>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12}>
                <Card>
                  <CardHeader color="rose" icon>
                    <CardIcon color="rose">
                      <Assignment />
                    </CardIcon>
                    <h4 className={classes.cardIconTitle}>Semnare</h4>
                  </CardHeader>
                  <CardBody>Nu s-au gasit documente</CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      );
    }

    return (
      <Card>
        <CardHeader color="warning" text>
          <CardText className={classes.cardText} color="danger">
            <h4 style={{ width: "100%" }} className={classes.cardTitleWhite}>
              Semnare
            </h4>
            <h4 className={classes.cardCategoryWhite}>
                            <i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
                            <i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
                            <i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
                            <i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
                            <i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
                            <i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
                            <i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
                            <i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
                            <i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,1)"}} className="fas fa-circle"></i>
                            <i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
            </h4>
          </CardText>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="rose">
                    <Assignment />
                  </CardIcon>
                  <h4 className={classes.cardIconTitle}>Semnare</h4>
                </CardHeader>
                <CardBody>
                  <Table
                    tableHead={["#", "Document", ""]}
                    tableData={that.getTableData()}
                    customCellClasses={[
                      classes.center,
                      classes.left,
                      classes.right
                    ]}
                    customClassesForCells={[0, 1, 2]}
                    customHeadCellClasses={[
                      classes.center,
                      classes.left,
                      classes.right
                    ]}
                    customHeadClassesForCells={[0, 1, 2]}
                  />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
          <Dialog
            fullScreen={true}
            classes={{
              root: classes.center,
              paper: classes.modal
            }}
            open={this.state.modal}
            transition={Transition}
            keepMounted
            onClose={() => this.handleClose("modal")}
            aria-labelledby="modal-slide-title"
            aria-describedby="modal-slide-description"
          >
            <DialogTitle
              id="classic-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
            >
              <h4 className={classes.modalTitle}>Semnare Document</h4>
            </DialogTitle>
            <DialogContent
              id="modal-slide-description"
              className={classes.modalBody}
            >
              <div className={classes.schetchFieldDiv} style={{width:this.state.utils.signWidth,height:this.state.utils.signHeight,border: "1px solid grey",left: "5px",position: "absolute"}}>
                  <SketchField
                    ref={ref => (this.state.canvas[0] = ref)}
                    width={this.state.utils.signWidth}
                    height={this.state.utils.signHeight}
                    tool={Tools.Pencil}
                    lineColor="black"
                    lineWidth={2}
                  />
              </div>
            </DialogContent>
            <DialogActions
              className={classes.modalFooter + " " + classes.modalFooterCenter}
            >
              <Button onClick={() => this.handleClose("modal")} color="simple">
                Close
              </Button>
              <Button
                onClick={() => this.HandleDone()}
                block
                color="success"
              >
                Aplica
              </Button>
            </DialogActions>
          </Dialog>
        </CardBody>
      </Card>
    );
  }
}

export default withStyles(style)(StepCustomerSign);
