import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from "@material-ui/core/MenuItem";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardText from "components/Card/CardText.jsx";
import CardBody from "components/Card/CardBody.jsx";
import deburr from "lodash/deburr";
import Autosuggest from "react-autosuggest";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";


// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import customSelectStyle from "assets/jss/restart-energy/customSelectStyle.jsx";
import customCheckboxRadioSwitch from "assets/jss/restart-energy/customCheckboxRadioSwitch.jsx";


import * as utils from "helper/utils.js";
import * as data from "helper/data.js";


let locationFilterData = [];

let localitate = "localitatea";

function renderInputComponent(inputProps) {
  const {
    classes, inputRef = () => {
    }, ref, ...other
  } = inputProps;

  return (
    <TextField
      fullWidth
      InputProps={{
        inputRef: node => {
          ref(node);
          inputRef(node);
        },
        classes: {
          input: classes.input
        }
      }}
      {...other}
    />
  );
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  const matches = match(suggestion.nume, query);
  const parts = parse(suggestion.nume, matches);

  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {parts.map((part, index) =>
          part.highlight ? (
            <span key={String(index)} style={{ fontWeight: 500 }}>
              {part.text}
            </span>
          ) : (
            <strong key={String(index)} style={{ fontWeight: 300 }}>
              {part.text}
            </strong>
          )
        )}
      </div>
    </MenuItem>
  );
}

function getSuggestions(value) {
  let that = this;
  const inputValue = deburr(value.trim()).toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;
  return inputLength === 0
    ? []
    : locationFilterData.filter(suggestion => {
      const keep =
        count < 5 && suggestion.nume.slice(0, inputLength).toLowerCase() === inputValue;

      if (keep) {
        count += 1;
      }
      return keep;
    });
}

function getSuggestionValue(suggestion) {
  this.wizardManger.setDataValue("location", suggestion);
  this.state.locationState = "success";
  return suggestion.nume;
}

const style = theme => ({
  cardText: {
    width: "100%",
    textAlign: "center"
  },
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  choiche: {
    textAlign: "center",
    cursor: "pointer",
    marginTop: "20px"
  },
  container: {
    position: "relative"
  },
  suggestionsContainerOpen: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0
  },
  suggestion: {
    display: "block"
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: "none"
  },
  divider: {
    height: theme.spacing.unit * 2
  },
  ...customSelectStyle,
  ...withStyles,
  ...customCheckboxRadioSwitch
});

class StepSelectLocation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      location: "",
      locationState: "",
      utils: utils.utils(),
      single: "",
      popper: "",
      suggestions: []
    };

    this.wizardManger = window.wizardManager;
  }

  reloadState() {
    locationFilterData = [];
    this.setState({ locationData: null});
  }

  sendState() {
    return this.state;
  }

  handleSimple = event => {
    let selected = {};

    for (let value of this.state.locationData) {
      if (value.id === event.target.value) {
        selected = value;
      }
    }

    this.wizardManger.setDataValue("location", selected);
    this.setState({ [event.target.name]: event.target.value });
  };


  isValidated() {
    if (
      this.state.locationState === "success"
    ) {
      return true;
    } else {
      if (this.state.locationState !== "success") {
        return false;
      }
    }
    return false;
  }

  loadLocationData() {
    debugger;
    let that = this;
    locationFilterData = [];

    if (this.wizardManger.wizardData.step.stepId === "select_location" &&
      typeof this.wizardManger.wizardData.type !== "undefined" && this.wizardManger.wizardData.type) {
      this.wizardManger.loadLocationData().then(function(data) {
        that.loadingLocationData = false;
        that.setState({ locationData: data.reZoneOperareList });
        for(var i=0; i<data.reZoneOperareList.length; i++){
          locationFilterData.push(data.reZoneOperareList[i]);
        }
      })
        .catch(function(err) {
          that.loadingLocationData = false;
          alert(err);
        });
    }

    that.loadingLocationData = false;
  }


  handleSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: getSuggestions(value),
    });
  }

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  }

  handleChange = name => (event, { newValue }) => {
    this.setState({
      [name]: newValue,
    });
  }


  render() {
    if (window.wizardManager.wizardData.type == "electricity"){
      localitate = "judetul";
    } else {
      localitate = "localitatea (judetul)";
    }
    const { classes } = this.props;

    const autosuggestProps = {
      renderInputComponent,
      suggestions: this.state.suggestions,
      onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
      onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
      getSuggestionValue,
      utils: utils.utils(),
      wizardManger: window.wizardManager,
      state: this.state,
      renderSuggestion
    };

    if (!this.state.locationData) {
      if (!this.loadingLocationData) {
        this.loadingLocationData = true;
        this.loadLocationData();
      }

      return <div>Loading...</div>;
    }

    var itemlist = this.state.locationData.map(function(item) {
      return (
        <MenuItem
          classes={{
            root: classes.selectMenuItem,
            selected: classes.selectMenuItemSelected
          }}
          value={item.id}
        >
          {item.nume}
        </MenuItem>
      );
    });

    return (
      <div>
        <h4 className={classes.infoText}></h4>
        <GridContainer justify="center">
          <Card>
            <CardHeader color="warning" text>
              <CardText className={classes.cardText} color="info">
                <h4 style={{ width: "100%" }} className={classes.cardTitleWhite}>Alege {localitate}</h4>
                <h4 className={classes.cardCategoryWhite}>
                  <i style={{ fontSize: "10px", marginRight: "1px", color: "rgba(255,255,255,0.3)" }}
                     className="fas fa-circle"></i>
                  <i style={{ fontSize: "10px", marginRight: "1px", color: "rgba(255,255,255,1)" }}
                     className="fas fa-circle"></i>
                  <i style={{ fontSize: "10px", marginRight: "1px", color: "rgba(255,255,255,0.3)" }}
                     className="fas fa-circle"></i>
                  <i style={{ fontSize: "10px", marginRight: "1px", color: "rgba(255,255,255,0.3)" }}
                     className="fas fa-circle"></i>
                  <i style={{ fontSize: "10px", marginRight: "1px", color: "rgba(255,255,255,0.3)" }}
                     className="fas fa-circle"></i>
                  <i style={{ fontSize: "10px", marginRight: "1px", color: "rgba(255,255,255,0.3)" }}
                     className="fas fa-circle"></i>
                  <i style={{ fontSize: "10px", marginRight: "1px", color: "rgba(255,255,255,0.3)" }}
                     className="fas fa-circle"></i>
                  <i style={{ fontSize: "10px", marginRight: "1px", color: "rgba(255,255,255,0.3)" }}
                     className="fas fa-circle"></i>
                  <i style={{ fontSize: "10px", marginRight: "1px", color: "rgba(255,255,255,0.3)" }}
                     className="fas fa-circle"></i>
                  <i style={{ fontSize: "10px", marginRight: "1px", color: "rgba(255,255,255,0.3)" }}
                     className="fas fa-circle"></i>
                </h4>
              </CardText>
            </CardHeader>
            <CardBody>
              <GridItem xs={12} sm={12}>
                <Autosuggest
                  {...autosuggestProps}
                  inputProps={{
                    classes,
                    placeholder: 'Introdu primele 3 litere',
                    value: this.state.single,
                    onChange: this.handleChange('single'),
                  }}
                  theme={{
                    container: classes.container,
                    suggestionsContainerOpen: classes.suggestionsContainerOpen,
                    suggestionsList: classes.suggestionsList,
                    suggestion: classes.suggestion,
                  }}
                  renderSuggestionsContainer={options => (
                    <Paper {...options.containerProps} square>
                      {options.children}
                    </Paper>
                  )}
                />
              </GridItem>
            </CardBody>
          </Card>

        </GridContainer>
      </div>
    );
  }
}

export default withStyles(style)(StepSelectLocation);
