import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { withRouter } from 'react-router-dom'

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardText from "components/Card/CardText.jsx";
import CardBody from "components/Card/CardBody.jsx";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import * as utils from "helper/utils.js";
import CircularProgress from '@material-ui/core/CircularProgress';






const style = {
    cardText:{
        width:"100%",
        textAlign:"center"
    },
    infoText: {
        fontWeight: "300",
        margin: "10px 0 30px",
        textAlign: "center"
    },
    inputAdornmentIcon: {
        color: "#555"
    },
    inputAdornment: {
        position: "relative"
    },
    notifMessage: {
        display: "inline-block",
        overflow: "auto",
        marginLeft: "10px",
        marginTop:  "18px"
    }
};

class StepPrezentareInfoClientData extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            utils: utils.utils(),
            progressIndicatorOn: false
        };

        this.wizardManger = window.wizardManager;
    }

    componentDidMount(){
        this.doGatherLocation();
    }

    componentDidUpdate(){
        this.doGatherLocation();
    }

    doGatherLocation(){
        let that = this;
        this.wizardManger
            .getLocationInfo()
            .then(function(data) {
                that.wizardManger.wizardData.geoLocation = data;

                if ((typeof that.wizardManger.wizardData.geoLocationAddr === 'undefined' || typeof that.wizardManger.wizardData.geoLocationAddr === null)
                && typeof that.wizardManger.wizardData.geoLocation.latitude !== 'undefined' && that.wizardManger.wizardData.geoLocation.latitude !== null
                && typeof that.wizardManger.wizardData.geoLocation.longitude !== 'undefined' && that.wizardManger.wizardData.geoLocation.longitude !== null){

                    that.wizardManger
                    .getReverseGeocoding(that.wizardManger.wizardData.geoLocation.latitude, that.wizardManger.wizardData.geoLocation.longitude)
                    .then(function(data) {
                        that.wizardManger.wizardData.geoLocationAddr = data;
                    })
                    .catch(function(err) {
                        console.log(err);
                    });
                }
            })
            .catch(function(err) {
                console.log(err);
            });
    }

    doFinalize(){
        let that = this;
        that.setState({progressIndicatorOn: true});
        this.wizardManger.createReDocument()
            .then(function(resp){
                that.setState({progressIndicatorOn: false});
                alert("Inregistrare creata!");
                that.props.history.push('/dashboard');
            })
            .catch(function(err){
                that.setState({progressIndicatorOn: false});
                alert("A avut loc o eroare: " + err);
            });
    }

    getProgressIndicator(){
        if (!this.state.progressIndicatorOn){
            return("");
        }

        return(
            <div>
                <CircularProgress
                    className={this.props.classes.progress}
                />

                <h4 className={this.props.classes.notifMessage}>Se creaza inregistrarea</h4>
            </div>
        );
    }

    render() {
        let that = this;
        const { classes } = this.props;
        return (
            <GridContainer justify="center">
                <Card>
                    <CardHeader color="warning" text>
                        <CardText className={classes.cardText} color="info">
                            <h4 style={{width:"100%"}} className={classes.cardTitleWhite}>Prezentare informatii</h4>
                            <h4 className={classes.cardCategoryWhite}>
                                <i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
                                <i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
                                <i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
                                <i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
                                <i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
                                <i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
                                <i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
                                <i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
                                <i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
                                <i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,1)"}} className="fas fa-circle"></i>
                            </h4>
                        </CardText>
                    </CardHeader>
                    <CardBody>
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                            <div style={{"marginTop":'10px', "marginBottom":'10px'}}>
                                <Table className={classes.table}>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="right">Tip</TableCell>
                                            <TableCell align="right">{window.wizardManager.wizardData.type=='electricity' ? 'Energie' : 'gas'}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right">Locatie</TableCell>
                                            <TableCell align="right">{checkNull('location', 'nume')}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right">Oferta</TableCell>
                                            <TableCell align="right">{checkNull('offer', 'nume')}</TableCell>
                                        </TableRow>
                                                    <strong>Detalii consumator :</strong>
                                        <TableRow>
                                            <TableCell align="right">Nume consumator</TableCell>
                                            <TableCell align="right">{checkNull('customer', 'nume_consumator')}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right">CNP consumator</TableCell>
                                            <TableCell align="right">{checkNull('customer', 'cnp')}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right">Pasaport consumator</TableCell>
                                            <TableCell align="right">{checkNull('customer', 'pasaport')}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right">Adresa domiciliu</TableCell>
                                            <TableCell align="right">{checkNull('customer', 'address')}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right">Email</TableCell>
                                            <TableCell align="right">{checkNull('customer', 'email')}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right">Telefon</TableCell>
                                            <TableCell align="right">{checkNull('customer', 'phone')}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right">Serie buletin/pasaport</TableCell>
                                            <TableCell align="right">{checkNull('customer', 'serie_buletin_pasaport')}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right">Numar buletin/pasaport</TableCell>
                                            <TableCell align="right">{checkNull('customer', 'numar_buletin_pasaport')}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right">Emitent buletin/pasaport</TableCell>
                                            <TableCell align="right">{checkNull('customer', 'emitent_buletin_pasaport')}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right">Valid de la</TableCell>
                                            <TableCell align="right">{checkNull('customer', 'val_de_la')}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right">Valid pana la</TableCell>
                                            <TableCell align="right">{checkNull('customer', 'val_pana_la')}</TableCell>
                                        </TableRow>
                                        <strong>Informatii loc consum :</strong>
                                        <TableRow>
                                            <TableCell align="right">Furnizor actual</TableCell>
                                            <TableCell align="right">{checkNull('locConsum', 'furnizor_actual')}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right">Adresa loc de consum</TableCell>
                                            <TableCell align="right">{checkNull('locConsum', 'adresa_loc_consum')}</TableCell>
                                        </TableRow>
                                        {getEEGNFields(window.wizardManager.wizardData.type, this)}
                                        {getGasErnergFields(window.wizardManager.wizardData.type, window.wizardManager.wizardData.subscriptionType, this)}
                                                    <strong>Detalii contractuale :</strong>
                                        <TableRow>
                                            <TableCell align="right">Data de incepere a furnizarii</TableCell>
                                            <TableCell align="right">{checkNull('detaliiContractuale', 'data_incepere_furnizarii')}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right">Cale de transmitere a facturii</TableCell>
                                            <TableCell align="right">{checkNull('detaliiContractuale', 'cale_transmitere_factura')}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right">Adresa de corespondenta</TableCell>
                                            <TableCell align="right">{checkNull('detaliiContractuale', 'adresa_corespondenta')}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right">Data de semnare a contractului</TableCell>
                                            <TableCell align="right">{checkNull('detaliiContractuale', 'data_semnare_contract')}</TableCell>
                                        </TableRow>
                                                        <strong>G.D.P.R. :</strong>
                                        <TableRow>
                                            <TableCell align="right">Caracter comercial</TableCell>
                                            <TableCell align="right">{checkNull('formularGdpr', 'info_caracter_comercial')}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right">Reclama publicitate</TableCell>
                                            <TableCell align="right">{checkNull('formularGdpr', 'reclama_publicitate')}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right">Grad multumire</TableCell>
                                            <TableCell align="right">{checkNull('formularGdpr', 'grad_multumire')}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </div>
                        </GridItem>
                        {that.getProgressIndicator()}
                    </CardBody>
                </Card>
            </GridContainer>
        );
    }
}
export default withRouter(withStyles(style)(StepPrezentareInfoClientData));

function checkNull(val, ret){
    if (window.wizardManager.wizardData[val] != null && window.wizardManager.wizardData[val][ret] != null){
        let dateValue = window.wizardManager.wizardData[val][ret];
        if (dateValue instanceof Date && !isNaN(dateValue) && window.wizardManager.wizardData[val][ret].toString() !== "true" && window.wizardManager.wizardData[val][ret].toString() !== "false" && ret !="cantitate_lunara_estimata" && ret != "cantitate_anuala_estimata"){
            window.wizardManager.wizardData[val][ret+"_show"] = presentFormatedDate(dateValue);
            return window.wizardManager.wizardData[val][ret+"_show"];
        }
        if (window.wizardManager.wizardData[val][ret].toString() == "true"){
            window.wizardManager.wizardData[val][ret+"_show"] = "Da, sunt de acord";
            return window.wizardManager.wizardData[val][ret+"_show"];
        }
        if (window.wizardManager.wizardData[val][ret].toString() == "false"){
            window.wizardManager.wizardData[val][ret+"_show"] = "Nu, nu sunt de acord";
            return window.wizardManager.wizardData[val][ret+"_show"];
        }
        return window.wizardManager.wizardData[val][ret].toString();
    }

    return '';
}

function presentFormatedDate(val){
    var date = new Date(val);
    var day = date.getDate();
    var monthIndex = date.getMonth() + 1;
    var year = date.getFullYear();

    return day + '.' + monthIndex + '.' + year;
}

function getEEGNFields(state, val){
    switch(state){
        case 'gas':
            return <TableRow>
                <TableCell align="right">Cod CLC GN</TableCell>
                <TableCell align="right">{checkNull('locConsum', 'cod_clc_gn')}</TableCell>
            </TableRow>
        case 'electricity':
            return <div style={{display: 'contents'}}><TableRow>
                <TableCell align="right">Cod NLC</TableCell>
                <TableCell align="right">{checkNull('locConsum', 'cod_nlc')}</TableCell>
            </TableRow>
                <TableRow>
                    <TableCell align="right">Cod POD</TableCell>
                    <TableCell align="right">{checkNull('locConsum', 'cod_pod')}</TableCell>
                </TableRow></div>
    }
}

function getGasErnergFields(state, oferType, val){
    switch(state){
        case 'electricity':
            return <TableRow>
                <TableCell align="right">Cantitate lunara estimata MWh</TableCell>
                <TableCell align="right">{checkNull('locConsum', 'cantitate_lunara_estimata')}</TableCell>
            </TableRow>
        case 'gas':
            return getOfferTypeFields(oferType, val)

    }
}


function getOfferTypeFields(oferType, val) {
    switch (oferType) {
        case 'go':
            return <div style={{display: 'contents'}}><TableRow>
                <TableCell align="right">Numar contract furnizare</TableCell>
                <TableCell align="right">{checkNull('locConsum', 'nr_contract_furnizare')}</TableCell>
            </TableRow>
                <TableRow>
                    <TableCell align="right">Cantitate anuala estimata MWh</TableCell>
                    <TableCell align="right">{checkNull('locConsum', 'cantitate_anuala_estimata')}</TableCell>
                </TableRow></div>
        case 'fix':
            return <div style={{display: 'contents'}}><TableRow>
                <TableCell align="right">Numar contract furnizare</TableCell>
                <TableCell align="right">{checkNull('locConsum', 'nr_contract_furnizare')}</TableCell>
            </TableRow>
                <TableRow>
                    <TableCell align="right">Cantitate lunara estimata MWh</TableCell>
                    <TableCell align="right">{checkNull('locConsum', 'cantitate_lunara_estimata')}</TableCell>
                </TableRow></div>
    }
}