import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import DatePicker from 'react-mobile-datepicker';
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardText from "components/Card/CardText.jsx";
import CardBody from "components/Card/CardBody.jsx";
import ImageUpload from "components/CustomUpload/ImageUpload.jsx";
import CircularProgress from '@material-ui/core/CircularProgress';
import Autosuggest from "react-autosuggest";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import customSelectStyle from "assets/jss/restart-energy/customSelectStyle.jsx";
import customCheckboxRadioSwitch from "assets/jss/restart-energy/customCheckboxRadioSwitch.jsx";
import deburr from "lodash/deburr";

import * as utils from "helper/utils.js";
let locationFilterData =[];
let judetFilterDate = [];
let localitatiFilterDate = [];
let straziFilterDate = [];
const dateConfig = {
    'year': {
        format: 'YYYY',
        caption: 'An',
        step: 1,
    },
    'month': {
        format: value => monthMap[value.getMonth() + 1],
        caption: 'Luna',
        step: 1,
    },
    'date': {
        format: 'DD',
        caption: 'Zi',
        step: 1,
    },
};
const monthMap = {
    '1': 'Ian',
    '2': 'Feb',
    '3': 'Mar',
    '4': 'Apr',
    '5': 'Mai',
    '6': 'Iun',
    '7': 'Iul',
    '8': 'Aug',
    '9': 'Sep',
    '10': 'Oct',
    '11': 'Nov',
    '12': 'Dec',
};
//const style = {
//  cardText: {
//    width: "100%",
//    textAlign: "center"
//  },
//  infoText: {
//    fontWeight: "300",
//    margin: "10px 0 30px",
//    textAlign: "center"
//  },
//  inputAdornmentIcon: {
//    color: "#555"
//  },
//  inputAdornment: {
//    position: "relative"
//  },
//  notifMessage: {
//      display: "inline-block",
//      overflow: "auto",
//      marginLeft: "10px",
//      marginTop:  "18px"
//  }
//};


const style = theme => ({
    cardText: {
        width: "100%",
        textAlign: "center"
    },
    infoText: {
        fontWeight: "300",
        margin: "10px 0 30px",
        textAlign: "center"
    },
    inputAdornmentIcon: {
        color: "#555"
    },
    inputAdornment: {
        position: "relative"
    },
    choiche: {
        textAlign: "center",
        cursor: "pointer",
        marginTop: "20px"
    },
    container: {
        position: "relative"
    },
    suggestionsContainerOpen: {
        position: "absolute",
        zIndex: 1,
        marginTop: theme.spacing.unit,
        left: 0,
        right: 0
    },
    suggestion: {
        display: "block"
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: "none"
    },
    divider: {
        height: theme.spacing.unit * 2
    },
...customSelectStyle,
...withStyles,
...customCheckboxRadioSwitch
});

function getSuggestionValue(suggestion) {
    //this.wizardManger.setDataValue("location", suggestion);
    //this.state.locationState = "success";
    if (typeof suggestion.addNew !== 'undefined' && suggestion.addNew[0].isAddNew) {
        return suggestion.addNew[1].value;
    }

    return suggestion.name;
}

function renderInputComponent(inputProps) {
    const {
        classes, inputRef = () => {
    }, ref, ...other
} = inputProps;
return (
    <TextField
fullWidth
InputProps={{
    inputRef: node => {
        ref(node);
        inputRef(node);
    },
        classes: {
        input: classes.input
    }
}}
{...other}
/>
);
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
    const matches = match(suggestion.name, query);
    const parts = parse(suggestion.name, matches);

    if (typeof suggestion.addNew !== 'undefined' && suggestion.addNew[0].isAddNew) {
        return (
            <MenuItem component="div">
                <div>
                    <span style={{ fontWeight: 500 }}>
                       [+] Adauga strada noua: {suggestion.addNew[1].value}
                    </span>
                </div>
            </MenuItem>
        );
    }

    return (
        <MenuItem selected={isHighlighted} component="div">
    <div>
    {parts.map((part, index) =>
        part.highlight ? (
        <span key={String(index)} style={{ fontWeight: 500 }}>
{part.text}
</span>
) : (
<strong key={String(index)} style={{ fontWeight: 300 }}>
{part.text}
</strong>
)
)}
</div>
</MenuItem>
);
}

function getSuggestions(value) {
    let that = this;
    const inputValue = deburr(value.trim()).toLowerCase();
    const inputLength = inputValue.length;
    let count = 0;
    return inputLength === 0
        ? []
        : judetFilterDate.filter(suggestion => {
        const keep =
            count < 5 && suggestion.name.slice(0, inputLength).toLowerCase() === inputValue;

        if (keep) {
            count += 1;
        }
        return keep;
    });
}
function getSuggestionsLocalitati(value) {
    let that = this;
    const inputValue = deburr(value.trim()).toLowerCase();
    const inputLength = inputValue.length;
    let count = 0;
    return inputLength === 0
        ? []
        : localitatiFilterDate.filter(suggestion => {
        const keep =
            count < 5 && suggestion.name.slice(0, inputLength).toLowerCase() === inputValue;

        if (keep) {
            count += 1;
        }
        return keep;
    });
}

function getSuggestionsStrazi(value) {
    let that = this;
    const inputValue = deburr(value.trim()).toLowerCase();
    const inputLength = inputValue.length;
    let count = 0;

    const suggestions = straziFilterDate.filter(suggestion => {
        const keep =
            count < 5 && suggestion.name.slice(0, inputLength).toLowerCase() === inputValue;

        if (keep) {
            count += 1;
        }
        return keep;
    });
    return inputLength === 0
        ? []
        : (suggestions.length === 0 ? [{addNew: [{isAddNew: true}, {value: value.trim()}, {inputName:  'd_strada'}]}] : suggestions);
}

class StepCustomerData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nume_consumator: "",
      nume_consumatorState: "",
      crm_call:"",
      cnp: "",
      cnpState: "",
      pasaport:"",
      pasaportState:"",
      address: "",
      addressState: "",
      d_tara: "Romania",
      d_judet: "",
      singleJudetId:"",
      d_judetState: "",
      d_localitate: "",
      singleLocalitatiId: "",
      d_localitateState: "",
      d_strada: "",
      singleStraziId:"",
      d_stradaState: "",
      d_nr: "",
      d_sector: "",
      d_bloc: "",
      d_scara: "",
      d_tronson: "",
      d_etaj: "",
      d_apartament: "",
      d_cod_postal: "",
      d_cod_postalState: "",
      email: "",
      emailState: "",
      phone: "",
      phoneState: "",
      serie_buletin_pasaport: "",
      serie_buletin_pasaportState: "",
      numar_buletin_pasaport: "",
      numar_buletin_pasaportState: "",
      emitent_buletin_pasaport: "",
      emitent_buletin_pasaportState: "",
      options : { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' },
      val_de_la: new Date(),
      val_de_laState:"",
      val_pana_la: new Date(),
        isOpenStart: false,
      val_pana_laState:"",
        isOpenEnd: false,
      scanProgressIndicatorOn: false,
      suggestions: [],
      suggestionsLocalitati: [],
      suggestionsStrazi: [],
      unlockLocalitati: true,
      unlockStrazi: true,
      crmClient: "",
      utils: utils.utils()
    };

    this.wizardManger = window.wizardManager;
    this.handleInputChange = this.handleInputChange.bind(this);

      this.handleSelectStart = this.handleSelectStart.bind(this);
      this.handleSelectEnd = this.handleSelectEnd.bind(this);

      this.handleCancelStart = this.handleCancelStart.bind(this);
      this.handleCancelEnd = this.handleCancelEnd.bind(this);

      this.handleClickStart = this.handleClickStart.bind(this);
      this.handleClickEnd = this.handleClickEnd.bind(this);

  }
  componentDidUpdate() {

  }

    handleClickStart = event => {
        event.preventDefault();
        this.setState({ isOpenStart: true });
        this.state.isLoadedStart=true;
    }
    handleClickEnd = event => {
        event.preventDefault();
        this.setState({ isOpenEnd: true });
        this.state.isLoadedEnd=true;
    }

    handleSelectStart = (val_de_la) => {
        this.setState({ val_de_la, isOpenStart: false,val_de_laState:"success" });
    }
    handleSelectEnd = (val_pana_la) => {
        this.setState({ val_pana_la, isOpenEnd: false,val_pana_laState:"success" });
    }

    handleCancelStart = () => {
        this.setState({ isOpenStart: false });
    }
    handleCancelEnd = () => {
        this.setState({ isOpenEnd: false });
    }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
    let that = this;
    this.wizardManger.loadCrmCall().then(function(data) {
      that.loadingLocationData = false;
      that.setState({ locationData: data.reZoneOperareList });
      for(var i=0; i<data.reZoneOperareList.length; i++){
        locationFilterData.push(data.reZoneOperareList[i]);
      }
    }).catch(function(err) {
          that.loadingLocationData = false;
          alert(err);
        });

  }

  checkPartnerByCNP(cnpValue){

      let that = this;
      this.wizardManger.checkPartnerByCNP(cnpValue).then(function(data) {

          that.wizardManger.setDataValue("crmClient", data);

          if (data.email != "undefined" && that.state.email.length == 0 && data.email != null){
              that.state.email = data.email;
          }
      }).catch(function(err) {
          alert(err);
      });
  }

    //handleSimple = event => {
    //    let selected = {};
    //
    //    for (let value of this.state.locationData) {
    //        if (value.id === event.target.value) {
    //            selected = value;
    //        }
    //    }
    //
    //    this.wizardManger.setDataValue("judetList", selected);
    //    this.setState({ [event.target.name]: event.target.value });
    //};

    loadJudeteData() {
        let that = this;
        judetFilterDate = [];

        if (this.wizardManger.wizardData.step.stepId === "customer_data" &&
            typeof this.wizardManger.wizardData.type !== "undefined" && this.wizardManger.wizardData.type) {
            this.wizardManger.loadJudeteData().then(function(data) {
                that.loadingJudetData = false;
                that.setState({ judetData: data.judetList });
                for(var i=0; i<data.judetList.length; i++){
                    judetFilterDate.push(data.judetList[i]);
                }
            })
                .catch(function(err) {
                    that.loadingJudetData = false;
                    alert(err);
                });
        }

        that.loadingJudetData = false;
    }

    loadLocalitatiData() {
        let that = this;
        localitatiFilterDate = [];

        if (this.wizardManger.wizardData.step.stepId === "customer_data" &&
            typeof this.wizardManger.wizardData.type !== "undefined" && this.wizardManger.wizardData.type) {
            this.wizardManger.loadLocalitatiData(this.state.singleJudetId).then(function(data) {
                that.loadingLocalitatiData = false;
                that.setState({ localitatiData: data.localitateList });
                for(var i=0; i<data.localitateList.length; i++){
                    localitatiFilterDate.push(data.localitateList[i]);
                }
            })
                .catch(function(err) {
                    that.loadingLocalitatiData = false;
                    alert(err);
                });
        }

        that.loadingLocalitatiData = false;
    }

    loadStraziData() {
        let that = this;
        straziFilterDate = [];

        if (this.wizardManger.wizardData.step.stepId === "customer_data" &&
            typeof this.wizardManger.wizardData.type !== "undefined" && this.wizardManger.wizardData.type) {
            this.wizardManger.loadStraziData(this.state.singleLocalitatiId).then(function(data) {

                that.loadingStraziData = false;
                that.setState({ straziData: data.straziList });
                for(var i=0; i<data.straziList.length; i++){
                    straziFilterDate.push(data.straziList[i]);
                }
            })
                .catch(function(err) {
                    that.loadingStraziData = false;
                    alert(err);
                });
        }

        that.loadingStraziData = false;
    }


    sendState() {
    return this.state;
  }
  // function that returns true if value is email, false otherwise
  verifyEmail(value) {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value) || value == "") {
      return true;
    }
    return false;
  }
  phoneNumber(value) {
    var phoneno = /^\d{10}$/;
    var fullPhonenoPlus = /^\d{11}$/;
    var fullPhoneno = /^\d{13}$/;

    if (value.charAt(0) == "+") {
      var val = value.slice(1, value.length);
      if (val.match(fullPhonenoPlus)) {
        return true;
      } else {
        return false;
      }
    }
    if (value.charAt(0) == "0" && value.charAt(1) == "0") {
      if (value.match(fullPhoneno)) {
        return true;
      } else {
        return false;
      }
    }
    if (value.match(phoneno)) {
      return true;
    } else {
      return false;
    }
  }


  mandatory(value) {
    return value !== "" && value !== null ? true : false;
  }

  validateCNP (value) {
    var re = /^\d{1}\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])(0[1-9]|[1-4]\d| 5[0-2]|99)\d{4}$/,
        bigSum = 0,
        rest = 0,
        ctrlDigit = 0,
        control = '279146358279',
        i = 0;
    if (re.test(value)) {
      for (i = 0; i < 12; i++) {
        bigSum += value[i] * control[i];
      }
      ctrlDigit = bigSum % 11;
      if (ctrlDigit === 10) {
        ctrlDigit = 1;
      }

      if (ctrlDigit !== parseInt(value[12], 10)) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  };

  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }
  change(event, stateName, type, stateNameEqualTo) {
    this.setState({ [stateName]: event.target.value });
    switch (type) {
      case "length":
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "phone":
        if (this.phoneNumber(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "email":
        if (this.verifyEmail(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "cnp":
          this.setState({ ["pasaport"]: " - " });
        if (this.validateCNP(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
    case "pasaport":
        this.setState({ ["cnpState"]: "success" });
        if (this.mandatory(event.target.value)) {
            this.setState({ [stateName + "State"]: "success" });
        } else {
            this.setState({ [stateName + "State"]: "error" });
        }
        break;
      default:
      case "mandatory":
        if (this.mandatory(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
    }

    this.wizardManger.setDataValue("customer", this.state);
  }

  continue() {
    if (this.state.phoneState === "success" && (this.state.emailState === "success" || this.state.emailState === "") && this.state.cnpState === "success") {
      this.wizardManger.setDataValue("customer", this.state);
      this.state.utils.triggerStepNext();
    }
  }

  setOrChangeImage(docId, imageData){
      if (typeof docId !== 'undefined' && docId !== null) {

          /* buletin */
          if (docId === 1) {
              this.setState({ci: imageData});
              this.wizardManger.setDataValue("customer", this.state);
          }
      }
  }

  removeImage(docId){
      if (typeof docId !== 'undefined' && docId !== null) {

          /* buletin */
          if (docId === 1) {
              this.setState({ ci: null});
              this.wizardManger.setDataValue("customer", this.state);
          }
      }
  }

  getCorectDate(val){
      let parts = val.split('.');
      if (typeof parts !== 'undefined' && parts.length == 3) {
          return new Date(parts[2], parts[1] - 1, parts[0]);
      }
      return "";
  }

  loadScannedData(data){
      if (typeof data.dms_cnp !== 'undefined' && data.dms_cnp !== null){
          this.change({target: {value: data.dms_cnp}}, "cnp", "cnp");
      }

      if (typeof data.seria !== 'undefined' && data.seria !== null){
          this.change({target: {value: data.seria}}, "serie_buletin_pasaport", "mandatory", 2);
      }

      if (typeof data.nr !== 'undefined' && data.nr !== null){
          this.change({target: {value: data.nr}},  "numar_buletin_pasaport", "mandatory", 6)
      }

      if (typeof data.emisa_de !== 'undefined' && data.emisa_de !== null){
          this.change({target: {value: data.emisa_de}},  "emitent_buletin_pasaport", "mandatory", 20)
      }

      try {
          if (typeof data.val_pana_la !== 'undefined' && data.val_pana_la !== null) {
                  let mydate = this.getCorectDate(data.val_pana_la);
                  if (mydate instanceof Date && !isNaN(mydate)) {
                      this.change({target: {value: mydate}}, "val_pana_la", "mandatory", 20);
                      this.state.isLoadedEnd = true;
                  }
          }
          if (typeof data.val_de_la !== 'undefined' && data.val_de_la !== null) {
              let input;
              let output;
              let result = '';
              if (typeof data.val_pana_la !== 'undefined' && data.val_pana_la !== null) {
                  if (data.val_de_la.length < data.val_pana_la.length) {
                      input = data.val_de_la.split(".");
                      output = data.val_pana_la.split(".");
                      let count = 0;
                      for (count = 0; count < input.length; count++) {
                          if (count == 2) {
                              let addded;
                              let i = 0;
                              for (i = 0; i < output.length; i++) {
                                  if (i = 2) {
                                      addded = output[i].substring(0, 2) + input[count];
                                  }
                              }
                              result = result + addded;
                          } else {
                              result = result + input[count] + ".";
                          }
                      }

                      let val = this.getCorectDate(result);
                      if (val instanceof Date && !isNaN(val)) {
                          this.change({target: {value: val}}, "val_de_la", "mandatory", 20);
                          this.state.isLoadedStart = true;
                      }
                  } else {
                      let val = this.getCorectDate(data.val_de_la);
                      if (val instanceof Date && !isNaN(val)) {
                          this.change({target: {value: val}}, "val_de_la", "mandatory", 20);
                          this.state.isLoadedStart = true;
                      }
                  }
              }
          }
      }catch(err){

      }
      //
      //if (typeof data.addr_judet !== 'undefined' && data.addr_judet !== null){
      //    this.change({target: {value: data.addr_judet}},  "d_judet", "mandatory")
      //}
      //
      //if (typeof data.addr_sec !== 'undefined' && data.addr_sec !== null){
      //    this.change({target: {value: data.addr_sec}},  "d_sector", "mandatory")
      //}
      //
      //if (typeof data.addr_strada !== 'undefined' && data.addr_strada !== null){
      //    this.change({target: {value: data.addr_strada}}, "d_strada", "mandatory")
      //}
      //
      //if (typeof data.addr_nr !== 'undefined' && data.addr_nr !== null){
      //    this.change({target: {value: data.addr_nr}}, "d_nr", "mandatory")
      //}
      //
      //if (typeof data.addr_bloc !== 'undefined' && data.addr_bloc !== null){
      //    this.change({target: {value: data.addr_bloc}}, "d_bloc", "length", 400)
      //}
      //
      //if (typeof data.addr_scara !== 'undefined' && data.addr_scara !== null){
      //    this.change({target: {value: data.addr_scara}}, "d_scara", "length", 400)
      //}
      //
      //if (typeof data.addr_appart !== 'undefined' && data.addr_appart !== null){
      //    this.change({target: {value: data.addr_appart}}, "d_apartament", "length", 400)
      //}

      let nume = null;
      if (typeof data.dms_prenume !== 'undefined' && data.dms_prenume !== null){
          nume = data.dms_prenume;
      }
      if (typeof data.dms_nume !== 'undefined' && data.dms_nume !== null){
        nume += ' ' + data.dms_nume;
      }
      if (nume !== null){
          nume = nume.trim();
          this.change({target: {value: nume}}, "nume_consumator", "mandatory");
      }
  }

  scanBuletin(){
      let that = this;

      if (typeof this.state.ci === 'undefined' || this.state.ci === null){
          alert('Selectati imaginea pentru scanare.');
          return;
      }

      that.setState({scanProgressIndicatorOn: true});
      this.wizardManger.scanOcr(this.state.ci)
          .then(function(resp){
              that.setState({scanProgressIndicatorOn: false});
              that.loadScannedData(resp);
              alert("Imagine procesata!");
          })
          .catch(function(err){
              that.setState({scanProgressIndicatorOn: false});
              alert("A avut loc o eroare: " + err);
          });

      if (this.state.cnp.length > 0) {
          this.checkPartnerByCNP(this.state.cnp);
      }
  }

  getScanProgressIndicator(){
      if (!this.state.scanProgressIndicatorOn){
          return("");
      }

      return(
          <div>
              <CircularProgress
                  className={this.props.classes.progress}
              />

              <h4 className={this.props.classes.notifMessage}>Se proceseaza</h4>
          </div>
      );
  }

  setAdress(){
    this.state.address="";
    this.checkNullAndAdd("Tara", this.state.d_tara);
    this.checkNullAndAdd("Judet", this.state.d_judet);
    this.checkNullAndAdd("Localitate", this.state.d_localitate);
    this.checkNullAndAdd("Str.", this.state.d_strada);
    this.checkNullAndAdd("Nr.",this.state.d_nr);
    this.checkNullAndAdd("Sec.",this.state.d_sector);
    this.checkNullAndAdd("Bl.",this.state.d_bloc);
    this.checkNullAndAdd("Sc.",this.state.d_scara);
    this.checkNullAndAdd("Trons.",this.state.d_tronson);
    this.checkNullAndAdd("Et.",this.state.d_etaj);
    this.checkNullAndAdd("Ap.",this.state.d_apartament);
    this.checkNullAndAdd("Cod pos.",this.state.d_cod_postal);
  }

  checkNullAndAdd(name, val){
    if (val !== "" && val !== null){
      if (!this.state.address.includes(",") && Object.keys(this.state.address).length == 0){
        this.state.address = name + " " + val;
      } else {
        this.state.address = this.state.address + ", " + name + " " + val;
      }
    }
  }

  reloadState() {
    this.wizardManger.setDataValue("customer", null);
    this.setState({ customer: "", customerData: null });
    this.setState({ judetData: null});
  }
  allMandatoryValid(){
    if (this.state.numar_buletin_pasaportState === "success" &&
        this.state.d_judetState === "success" &&
        this.state.d_localitateState === "success" &&
        this.state.d_stradaState === "success" &&
        this.state.serie_buletin_pasaportState === "success" &&
        this.state.emitent_buletin_pasaportState === "success" &&
        this.state.nume_consumatorState === "success" &&
        this.state.phoneState === "success" &&
        this.state.cnpState === "success" &&
        (this.state.emailState === "success" || this.state.emailState === "")) {
    return true;
    }
    return false;
    }
  isValidated() {
    if (this.allMandatoryValid() && this.state.val_de_laState === "success" && this.state.val_pana_laState === "success") {
        this.setAdress();
        this.wizardManger.setDataValue("customer", this.state);

      return true;
    } else {
        if (this.state.val_de_laState !== "success"){
            this.setState({ val_de_laState: "error" });
        } else {
            this.setState({ val_de_laState: "success" });
        }
        if (this.state.val_pana_laState !== "success"){
            this.setState({ val_pana_laState: "error" });
        } else {
            this.setState({ val_pana_laState: "success" });
        }
      if (this.state.phoneState !== "success") {
        this.setState({ phoneState: "error" });
      }
      if (this.state.cnpState !== "success") {
        this.setState({ cnpState: "error" });
      }
      if (this.state.nume_consumatorState !== "success") {
        this.setState({ nume_consumatorState: "error" });
      }
      if (this.state.numar_buletin_pasaportState !== "success") {
        this.setState({ numar_buletin_pasaportState: "error" });
      }
      if (this.state.serie_buletin_pasaportState !== "success") {
        this.setState({ serie_buletin_pasaportState: "error" });
      }
      if (this.state.emitent_buletin_pasaportState !== "success") {
        this.setState({ emitent_buletin_pasaportState: "error" });
      }
      if (this.state.d_judetState !== "success") {
        this.setState({ d_judetState: "error" });
      }
      if (this.state.d_localitateState !== "success") {
        this.setState({ d_localitateState: "error" });
      }
      if (this.state.d_stradaState !== "success") {
        this.setState({ d_stradaState: "error" });
      }
    }
    return false;
  }

handleSuggestionsFetchRequested = ({ value }) => {
    this.setState({
        suggestions: getSuggestions(value),
    });
}

handleSuggestionsClearRequested = () => {
    this.setState({
        suggestions: []
    });
}

handleSuggestionsFetchRequestedLocalitati = ({ value }) => {
    this.setState({
        suggestionsLocalitati: getSuggestionsLocalitati(value),
    });
}

handleSuggestionsClearRequestedLocalitati = () => {
    this.setState({
        suggestionsLocalitati: []
    });
}

handleSuggestionsFetchRequestedStrazi = ({ value }) => {
    this.setState({
        suggestionsStrazi: getSuggestionsStrazi(value),
    });
}

handleSuggestionsClearRequestedStrazi = () => {
    this.setState({
        suggestionsStrazi: []
    });
}

onSuggestionSelectedStrazi = (event, { suggestion }) => {
    if (typeof suggestion.addNew !== 'undefined' && suggestion.addNew[0].isAddNew) {
        this.state[suggestion.addNew[2].inputName + "State"] = "success";
        //this.state.d_cod_postalState = "error";
        this.state.d_cod_postal = "";
        //console.log('Add new:', suggestion.addNew[1].value);
    }
};
handleChange = name => (event, { newValue }) => {


    this.setState({[name + "State"]: "error"});
    if (judetFilterDate != "undefined" && Object.keys(judetFilterDate).length > 0 && name == "d_judet") {
        this.setState({
            [name]: newValue
        });
        let bol = false;
        let lngth = Object.keys(judetFilterDate).length;
        let i = 0;
        this.state.unlockLocalitati = true;
        this.state.unlockStrazi = true;
        this.state.d_localitate = "";
        this.state.d_strada = "";
        this.state.singleLocalitatiId = "";
        this.state.singleStraziId = "";
        for (i = 0; i < lngth; i++) {
            if (judetFilterDate[i].name == newValue) {
                bol = true;
                this.state.unlockLocalitati = false;
                this.state.singleJudetId = judetFilterDate[i].countyId;
                this.loadLocalitatiData();
            }
        }
        if (bol) {
            this.setState({[name + "State"]: "success"});
        } else {
            this.setState({[name + "State"]: "error"});
        }
        return;
    }
    if (localitatiFilterDate != "undefined" && localitatiFilterDate.length > 0 && name == "d_localitate") {
        this.setState({
            [name]: newValue
        });
        let bol = false;
        let lngth = localitatiFilterDate.length;
        let i = 0;
        this.state.unlockStrazi = true;
        this.state.d_strada = "";
        this.state.singleLocalitatiId = "";
        this.state.singleStraziId = "";
        for (i = 0; i < lngth; i++) {
            if (localitatiFilterDate[i].name == newValue) {
                this.state.unlockStrazi = false;
                this.state.singleLocalitatiId = localitatiFilterDate[i].localityId;
                this.loadStraziData();
                bol = true;
            }
        }
        if (bol) {
            this.setState({[name + "State"]: "success"});
        } else {
            this.setState({[name + "State"]: "error"});
        }
        return;
    }

    if (straziFilterDate != "undefined" && straziFilterDate.length > 0 && name == "d_strada") {
        this.setState({
            [name]: newValue
        });
        let bol = false;
        let lngth = straziFilterDate.length;
        let i = 0;
        this.state.singleStraziId = "";
        for (i = 0; i < lngth; i++) {
            if (straziFilterDate[i].name == newValue) {
                bol = true;
                this.state.singleStraziId = straziFilterDate[i].streetId;
                this.state.d_cod_postal = straziFilterDate[i].zipCode;
                this.state.d_cod_postalState = "success";
            }
        }
        if (bol) {
            this.setState({[name + "State"]: "success"});
        } else {
            this.setState({[name + "State"]: "error"});
        }
        return;
    }
};

  render() {

    const autosuggestProps = {
        renderInputComponent,
        suggestions: this.state.suggestions,
        onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
        onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
        getSuggestionValue,
        utils: utils.utils(),
        wizardManger: window.wizardManager,
        state: this.state,
        renderSuggestion
    };

    const autosuggestPropsLocalitati = {
        renderInputComponent,
        suggestions: this.state.suggestionsLocalitati,
        onSuggestionsFetchRequested: this.handleSuggestionsFetchRequestedLocalitati,
        onSuggestionsClearRequested: this.handleSuggestionsClearRequestedLocalitati,
        getSuggestionValue,
        utils: utils.utils(),
        wizardManger: window.wizardManager,
        state: this.state,
        renderSuggestion
    };

    const autosuggestPropsStrazi = {
        renderInputComponent,
        suggestions: this.state.suggestionsStrazi,
        onSuggestionsFetchRequested: this.handleSuggestionsFetchRequestedStrazi,
        onSuggestionsClearRequested: this.handleSuggestionsClearRequestedStrazi,
        onSuggestionSelected: this.onSuggestionSelectedStrazi,
        getSuggestionValue,
        utils: utils.utils(),
        wizardManger: window.wizardManager,
        state: this.state,
        renderSuggestion
    };

    if (!this.state.judetData) {
        if (!this.loadingJudetData) {
            this.loadingJudetData = true;
            this.loadJudeteData();
        }

        return <div>Loading...</div>;
    }

    //var itemlist = this.state.judetData.map(function(item) {
    //    return (
    //        <MenuItem
    //    classes={{
    //        root: classes.selectMenuItem,
    //            selected: classes.selectMenuItemSelected
    //    }}
    //    value={item.id}
    //    >
    //    {item.name}
    //    </MenuItem>
    //    );
    //    });

    let that = this;
    const { classes } = this.props;
    return (
      <GridContainer justify="center">
        <Card>
          <CardHeader color="success" text>
            <CardText className={classes.cardText} color="success">
              <h4 style={{ width: "100%" }} className={classes.cardTitleWhite}>
                Date consumator
              </h4>
              <h4 className={classes.cardCategoryWhite}>
                <i
                  style={{
                    fontSize: "10px",
                    marginRight: "1px",
                    color: "rgba(255,255,255,0.3)"
                  }}
                  className="fas fa-circle"
                />
                <i
                  style={{
                    fontSize: "10px",
                    marginRight: "1px",
                    color: "rgba(255,255,255,0.3)"
                  }}
                  className="fas fa-circle"
                />
                <i
                  style={{
                    fontSize: "10px",
                    marginRight: "1px",
                    color: "rgba(255,255,255,0.3)"
                  }}
                  className="fas fa-circle"
                />
                <i
                  style={{
                    fontSize: "10px",
                    marginRight: "1px",
                    color: "rgba(255,255,255,0.3)"
                  }}
                  className="fas fa-circle"
                />
                <i
                  style={{
                    fontSize: "10px",
                    marginRight: "1px",
                    color: "rgba(255,255,255,1)"
                  }}
                  className="fas fa-circle"
                />
                <i
                  style={{
                    fontSize: "10px",
                    marginRight: "1px",
                    color: "rgba(255,255,255,0.3)"
                  }}
                  className="fas fa-circle"
                />
                <i
                  style={{
                    fontSize: "10px",
                    marginRight: "1px",
                    color: "rgba(255,255,255,0.3)"
                  }}
                  className="fas fa-circle"
                />
                <i
                  style={{
                    fontSize: "10px",
                    marginRight: "1px",
                    color: "rgba(255,255,255,0.3)"
                  }}
                  className="fas fa-circle"
                />
                <i
                  style={{
                    fontSize: "10px",
                    marginRight: "1px",
                    color: "rgba(255,255,255,0.3)"
                  }}
                  className="fas fa-circle"
                />
                <i
                    style={{
                        fontSize: "10px",
                        marginRight: "1px",
                        color: "rgba(255,255,255,0.3)"
                    }}
                    className="fas fa-circle"
                />
              </h4>
            </CardText>
          </CardHeader>
          <CardBody>
              <GridItem xs={12} sm={12} md={12}>
                  <div style={{textAlign:"center"}}>
                      <legend>Copie BI / CI / Pasaport</legend>
                      <ImageUpload
                          addButtonProps={{
                              color: "rose",
                              round: true
                          }}
                          changeButtonProps={{
                              color: "rose",
                              round: true
                          }}
                          removeButtonProps={{
                              color: "danger",
                              round: true
                          }}
                          advProps={{
                              id: 1,
                              parent: that
                          }}
                      />
                  </div>
                  <Button fullWidth="true" icon="" value="true" color="success" round inputProps={{
                      onClick: event => this.scanBuletin(),
                  }}><i class="fas fa-camera"></i>
                      Proceseaza CI
                  </Button>
                  <p>
                      {that.getScanProgressIndicator()}
                  </p>
              </GridItem>
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <CustomInput
                success={this.state.nume_consumatorState === "success"}
                error={this.state.nume_consumatorState === "error"}
                labelText={<span>Nume consumator <small>(obligatoriu)</small> </span>}
                id="nume_consumator"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: this.state.nume_consumator,
                  onChange: event =>
                    this.change(event, "nume_consumator", "mandatory")
                }}
              />
                {/*
<br/>
<br/>
<label>Call crm</label>
<input
name="crm_call"
type="checkbox"
checked={this.state.crm_call}
onChange={this.handleInputChange}
/>
*/}
              <CustomInput
                success={this.state.cnpState === "success"}
                error={this.state.cnpState === "error"}
                labelText={<span>CNP <small>(obligatoriu)</small> </span>}
                id="cnp"
                formControlProps={{
                  fullWidth: true
                }}

                inputProps={{
                disabled: this.state.pasaport && this.state.pasaport != " - ",
                  value: this.state.cnp,
                  onChange: event => this.change(event, "cnp", "cnp")
                }}
              />

                <CustomInput
                success={this.state.pasaportState === "success"}
                error={this.state.pasaport === "error"}
                labelText={<span>Pasaport <small>(obligatoriu)</small> </span>}
                id="pasaport"
                formControlProps={{
                    fullWidth: true
                }}

                inputProps={{
                    disabled: this.state.cnp,
                        value: this.state.pasaport,
                        onChange: event => this.change(event, "pasaport", "pasaport")
                }}
                />

              <CustomInput
                labelText={<span>Tara domiciliu</span>}
                id="d_tara"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: this.state.d_tara,
                  onChange: event =>
                    this.change(event, "d_tara", "length", 400)
                }}
              />
        <Autosuggest
        {...autosuggestProps}
        inputProps={{
            classes,
                label: 'Judet domiciliu (obligatoriu)',
                placeholder: 'Introdu primele 3 litere',
                success: this.state.d_judetState === "success",
                error: this.state.d_judetState === "error",
                value: this.state.d_judet,
                onChange: this.handleChange('d_judet')
        }}
        theme={{
            container: classes.container,
                suggestionsContainerOpen: classes.suggestionsContainerOpen,
                suggestionsList: classes.suggestionsList,
                suggestion: classes.suggestion,
        }}
        renderSuggestionsContainer={options => (
        <Paper {...options.containerProps} square>
        {options.children}
        </Paper>
        )}
        />

<Autosuggest
{...autosuggestPropsLocalitati}
inputProps={{
    classes,
        disabled: this.state.unlockLocalitati,
        label: 'Localitate domiciliu (obligatoriu)',
        placeholder: 'Introdu primele 3 litere',
    success: this.state.d_localitateState === "success",
    error: this.state.d_localitateState === "error",
        value: this.state.d_localitate,
        onChange: this.handleChange('d_localitate')
}}
theme={{
    container: classes.container,
        suggestionsContainerOpen: classes.suggestionsContainerOpen,
        suggestionsList: classes.suggestionsList,
        suggestion: classes.suggestion,
}}
renderSuggestionsContainer={options => (
<Paper {...options.containerProps} square>
{options.children}
</Paper>
)}
/>

<Autosuggest
{...autosuggestPropsStrazi}
inputProps={{
    classes,
        disabled: this.state.unlockStrazi,
        label: 'Strada domiciliu (obligatoriu)',
        placeholder: 'Introdu primele 3 litere',
    success: this.state.d_stradaState === "success",
    error: this.state.d_stradaState === "error",
        value: this.state.d_strada,
        onChange: this.handleChange('d_strada')
}}
theme={{
    container: classes.container,
        suggestionsContainerOpen: classes.suggestionsContainerOpen,
        suggestionsList: classes.suggestionsList,
        suggestion: classes.suggestion,
}}
renderSuggestionsContainer={options => (
<Paper {...options.containerProps} square>
{options.children}
</Paper>
)}
/>
                <CustomInput
                labelText={<span>Nr. domiciliu</span>}
                id="d_nr"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: this.state.d_nr,
                  onChange: event =>
                      this.change(event, "d_nr", "mandatory")
                }}
                />

                <CustomInput
                labelText={<span>Bloc domiciliu</span>}
                id="d_bloc"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: this.state.d_bloc,
                  onChange: event =>
                      this.change(event, "d_bloc", "length", 400)
                }}
                />

                <CustomInput
                labelText={<span>Scara domiciliu</span>}
                id="d_scara"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: this.state.d_scara,
                  onChange: event =>
                      this.change(event, "d_scara", "length", 400)
                }}
                />

                <CustomInput
                labelText={<span>Tronson domiciliu</span>}
                id="d_tronson"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: event =>
                      this.change(event, "d_tronson", "length", 400)
                }}
                />
                <CustomInput
                labelText={<span>Etaj domiciliu</span>}
                id="d_etaj"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: event =>
                      this.change(event, "d_etaj", "length", 400)
                }}
                />
                <CustomInput
                labelText={<span>Apartament domiciliu</span>}
                id="d_apartament"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: this.state.d_apartament,
                  onChange: event =>
                      this.change(event, "d_apartament", "length", 400)
                }}
                />
                <CustomInput
                    success={this.state.d_cod_postalState === "success"}
                    error={this.state.d_cod_postalState === "error"}
                labelText={<span>Cod postal domiciliu</span>}
                id="d_cod_postal"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                    value: this.state.d_cod_postal,
                  onChange: event =>
                      this.change(event, "d_cod_postal", "length", 20)
                }}
                />

            </GridItem>
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <CustomInput
                success={this.state.emailState === "success"}
                error={this.state.emailState === "error"}
                labelText={<span>Email</span>}
                id="email"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                    value: this.state.email,
                  onChange: event => this.change(event, "email", "email")
                }}
              />
              <CustomInput
                success={this.state.phoneState === "success"}
                error={this.state.phoneState === "error"}
                labelText={
                  <span>
                    Telefon <small>(obligatoriu)</small>
                  </span>
                }
                id="phone"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                    value: this.state.phone,
                  onChange: event => this.change(event, "phone", "phone")
                }}
              />
              <CustomInput
success={this.state.serie_buletin_pasaportState === "success"}
error={this.state.serie_buletin_pasaportState === "error"}
                labelText={<span>Serie buletin/pasaport <small>(obligatoriu)</small></span>}
                id="serie_buletin_pasaport"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: this.state.serie_buletin_pasaport,
                  onChange: event =>
                    this.change(event, "serie_buletin_pasaport", "mandatory", 2)
                }}
              />
              <CustomInput
success={this.state.numar_buletin_pasaportState === "success"}
error={this.state.numar_buletin_pasaportState === "error"}
                labelText={<span>Numar buletin/pasaport <small>(obligatoriu)</small></span>}
                id="numar_buletin_pasaport"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: this.state.numar_buletin_pasaport,
                  onChange: event =>
                    this.change(event, "numar_buletin_pasaport", "mandatory", 6)
                }}
              />
              <CustomInput
success={this.state.emitent_buletin_pasaportState === "success"}
error={this.state.emitent_buletin_pasaportState === "error"}
                labelText={<span>Emitent buletin/pasaport <small>(obligatoriu)</small></span>}
                id="emitent_buletin_pasaport"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                    value: this.state.emitent_buletin_pasaport,
                  onChange: event =>
                    this.change(event, "emitent_buletin_pasaport", "mandatory", 99)
                }}
              />




                <DatePicker
                    headerFormat="DD/MM/YYYY"
                    isPopup={true}
                    dateConfig={dateConfig}
                    showCaption={true}
                    confirmText="Selecteaza"
                    cancelText="Anuleaza"
                    value={this.state.val_de_la}
                    isOpen={this.state.isOpenStart}
                    onSelect={this.handleSelectStart}
                    onCancel={this.handleCancelStart} />


                <CustomInput
                    success={this.state.val_de_laState === "success"}
                    error={this.state.val_de_laState === "error"}
                    labelText={<span> Valid de la <small>(obligatoriu)</small></span>}
                    id="val_de_la"
                    formControlProps={{
                        fullWidth: true
                    }}
                    inputProps={{
                        value: this.state.isLoadedStart?this.state.val_de_la.toLocaleDateString("ro-RO", this.state.options):"",
                        onClick: event => this.handleClickStart(event),
                        onKeyup: event => function (event) {
                            event.preventDefault();
                        }
                    }}
                />



                <DatePicker
                    headerFormat="DD/MM/YYYY"
                    isPopup={true}
                    dateConfig={dateConfig}
                    showCaption={true}
                    confirmText="Selecteaza"
                    cancelText="Anuleaza"
                    value={this.state.val_pana_la}
                    isOpen={this.state.isOpenEnd}
                    onSelect={this.handleSelectEnd}
                    onCancel={this.handleCancelEnd} />


                <CustomInput
                    success={this.state.val_pana_laState === "success"}
                    error={this.state.val_pana_laState === "error"}
                    labelText={<span> Valid pana la <small>(obligatoriu)</small></span>}
                    id="val_pana_la"
                    formControlProps={{
                        fullWidth: true
                    }}
                    inputProps={{
                        value:this.state.isLoadedEnd?this.state.val_pana_la.toLocaleDateString("ro-RO", this.state.options):"",
                        onClick: event => this.handleClickEnd(event),
                        onKeyup: event => function (event) {
                            event.preventDefault();
                        }
                    }}
                />
            </GridItem>
          </CardBody>
        </Card>
      </GridContainer>
    );
  }
}

export default withStyles(style)(StepCustomerData);
