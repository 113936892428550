import axios from "axios/dist/axios";

export class LoginManager {
    constructor(props){
        this.STD_EXPIRE_CHECK_MINS = 10;

        this.config = {
            wsUrl : window.WS_URL,
            ocrUrl : window.OCR_URL,
            dmsUrl : window.DMS_URL,
            lightUrl : window.LIGHT_URL,
            previewerUrl: window.PREVIEWER_URL,
            geocodeKey: window.GEOCODE_KEY,
            corsHeaders: {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Credentials': 'true',
                'Access-Control-Allow-Methods': 'POST, PUT, GET, OPTIONS, DELETE, HEAD',
                'Access-Control-Allow-Headers': 'Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Credentials, Access-Control-Allow-Methods, Origin, Accept, X-Requested-With, Content-Type, X-PINGOTHER, Authorization'
            }
        };
    }

    storeUserDataInSession(){
        sessionStorage.setItem('userData', JSON.stringify(this.userData));
    }

    deleteUserDataInSession(){
      let promise = new Promise((resolve) => {
        sessionStorage.removeItem('userData');
        resolve();
      });

      return promise;
    }

    loadUserDataFromSession(){
        let userData = sessionStorage.getItem('userData');
        if (typeof userData === 'undefined' || userData ===  null || userData === ''){
            userData = "{}";
        }

        userData = JSON.parse(userData);
        this.userData = userData;
        return userData;
    }

    doLogin(username, password){
        let that = this;

        let promise = new Promise((resolve, reject) => {
            axios.put(this.config.wsUrl + '/api/v1/login/',
                {
                    username: username,
                    password: password
                },
                {
                    headers: {
                        ...this.config.corsHeaders
                    }
                }).then(function (response) {
                    if (typeof response !== 'undefined' && response
                        && typeof response.data !== 'undefined' && response.data
                        && typeof response.data.valid !== 'undefined' && response.data.valid){

                        that.userData = response.data;
                        that.storeUserDataInSession();
                        resolve(response);
                    } else {
                        var err = response.data.result_msg;
                        if (typeof err === 'undfined' || !err){
                            err = 'Unable to login.'
                        }

                        response.err = err;
                        reject(response);
                    }
                }).catch(function (response) {
                    reject(response);
                });
        });

        return promise;
    }

    doChangePassword(oldPassword, newPassword, newPassword2){
        let that = this;

        let promise = new Promise((resolve, reject) => {
            that.checkToken().then(function () {
                axios.post(that.config.wsUrl + '/api/v1/utilizator/' + that.userData.token +'/change_password2/',
                    {
                        oldPassword: oldPassword,
                        newPassword: newPassword,
                        newPassword2: newPassword2
                    },
                    {
                        headers: {
                            ...that.config.corsHeaders
                        }
                    }).then(function (response) {
                        if (typeof response !== 'undefined' && response
                            && typeof response.data !== 'undefined' && response.data
                            && typeof response.data.result !== 'undefined' && response.data.result === 'OK'){

                            resolve(response.data);
                        } else {
                            var err = response.data.info;
                            if (typeof err === 'undfined' || !err){
                                err = 'Unable to change password.'
                            }

                            reject(err);
                        }
                    }).catch(function (response) {
                        reject(response);
                    });
            }).catch(function (err) {
                reject(err);
            });
        });

        return promise;
    }

    checkToken(){
        let that = this;

        let promise = new Promise((resolve, reject) => {
            if (!that.extendNeeded()){
                resolve();
            } else {
                that.extendToken()
                    .then(function (response) {
                        resolve();
                    }).catch(function (response) {
                        reject(response);
                    });
            }
        });

        return promise;
    }

    extendToken(){
        let that = this;

        let promise = new Promise((resolve, reject) => {
            axios.put(this.config.wsUrl + '/api/v1/login/extend/' + this.userData.token,
                null,
                {
                    headers: {
                        ...this.config.corsHeaders
                    }
                }).then(function (response) {
                    if (typeof response !== 'undefined' && response
                        && typeof response.data !== 'undefined' && response.data
                        && typeof response.data.token !== 'undefined' && response.data.token
                        && typeof response.data.expires !== 'undefined' && response.data.expires){

                        that.userData.token = response.data.token;
                        that.userData.expires = response.data.expires;
                        that.storeUserDataInSession();
                        resolve(response);
                    } else {
                        var err = response.data.result_msg;
                        if (typeof err === 'undfined' || !err){
                            err = 'Unable to extend token.'
                        }

                        response.err = err;
                        reject(response);
                    }
                }).catch(function (response) {
                    reject(response);
                });
        });

        return promise;
    }

    extendNeeded(){
        return this.tokenExpiresInMinutes(this.STD_EXPIRE_CHECK_MINS) || this.tokenIsExpired();
    }

    tokenExpiresInMinutes(mins){
        if (typeof this.userData !== 'undefined' && this.userData !== null){
            let date = new Date(this.userData.expires);
            let now = new Date();

            return date.getTime() > now.getTime() && date.getTime() - now.getTime() <= mins * 1000 * 60;
        }

        return false;
    }

    tokenIsExpired(){
        if (typeof this.userData !== 'undefined' && this.userData !== null){
            let date = new Date(this.userData.expires);
            let now = new Date();

            return now.getTime() > date.getTime();
        }

        return false;
    }
}