import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardText from "components/Card/CardText.jsx";
import CardBody from "components/Card/CardBody.jsx";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


import * as utils from "helper/utils.js";






const style = {
    cardText:{
        width:"100%",
        textAlign:"center"
    },
    infoText: {
        fontWeight: "300",
        margin: "10px 0 30px",
        textAlign: "center"
    },
    inputAdornmentIcon: {
        color: "#555"
    },
    inputAdornment: {
        position: "relative"
    }
};

class StepFormularGdprData extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            info_caracter_comercial: false,
            reclama_publicitate: false,
            grad_multumire: false,
            utils: utils.utils()
        };

        this.wizardManger = window.wizardManager;
        this.handleInputChange = this.handleInputChange.bind(this);
    }


     handleInputChange(event) {
            const target = event.target;
            const value = target.type === 'checkbox' ? target.checked : target.value;
            const name = target.name;

            this.setState({
                [name]: value
            });
        }

    reloadState(){
        this.wizardManger.setDataValue('formularGdpr', null);
        this.setState({ formularGdpr: "", formularGdprData: null });
    }

    sendState() {
        return this.state;
    }
    // function that returns true if value is email, false otherwise
    verifyEmail(value) {
        var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (emailRex.test(value)) {
            return true;
        }
        return false;
    }
    phoneNumber(value)
    {
        var phoneno = /^\d{10}$/;
        if(value.match(phoneno))
        {
            return true;
        }
        else
        {
            return false;
        }
    }

    // function that verifies if a string has a given length or not
    verifyLength(value, length) {
        if (value.length >= length) {
            return true;
        }
        return false;
    }
    change(event, stateName, type, stateNameEqualTo) {
        this.setState({ [stateName]: event.target.value });
        switch (type) {
            case "length":
                if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                    this.setState({ [stateName + "State"]: "success" });
                } else {
                    this.setState({ [stateName + "State"]: "error" });
                }
                break;
            case "phone":
                if (this.phoneNumber(event.target.value)) {
                    this.setState({ [stateName + "State"]: "success" });
                } else {
                    this.setState({ [stateName + "State"]: "error" });
                }
                break;
            default:
                break;
        }
    }

    isValidated() {
            this.wizardManger.setDataValue('formularGdpr', this.state);
            return true;
            }
    render() {
        const { classes } = this.props;
        return (
            <GridContainer justify="center">
                <Card>
                    <CardHeader color="warning" text>
                        <CardText className={classes.cardText} color="success">
                            <h4 style={{width:"100%"}} className={classes.cardTitleWhite}>Formular acordare consimțământ prelucrare date cu caracter personal</h4>
                            <h4 className={classes.cardCategoryWhite}>
                                <i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
                                <i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
                                <i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
                                <i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,1)"}} className="fas fa-circle"></i>
                                <i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
                                <i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
                                <i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
                                <i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
                                <i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
                                <i style={{fontSize:"10px",marginRight:"1px",color:"rgba(255,255,255,0.3)"}} className="fas fa-circle"></i>
                            </h4>
                        </CardText>
                    </CardHeader>
                    <CardBody>
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                        Clientul își exprimă în mod expres consimțământul pentru prelucrarea următoarelor date cu caracter personal: nume, prenume, adresa de corespondență, adresa e-mail, număr de telefon,pentru următoarele scopuri suplimentare:
                            <br/>
                            <br/>
                            <label>a) pentru a primi informații cu caracter comercial în scopuri de marketing și comercializare a produselor si serviciilor furnizorului și partenerilor acestuia prin orice mijloc de comunicare:</label>
                            <input
                            name="info_caracter_comercial"
                            type="checkbox"
                            checked={this.state.info_caracter_comercial}
                            onChange={this.handleInputChange}
                            />

                            <br/>
                            <br/>
                            <label>b) reclama si publicitate</label>
                            <input
                            name="reclama_publicitate"
                            type="checkbox"
                            checked={this.state.reclama_publicitate}
                            onChange={this.handleInputChange}
                            />

                            <br/>
                            <br/>
                            <label>c) măsurarea gradului de mulțumire a clientului față de serviciile prestate de furnizor:</label>
                            <input
                            name="grad_multumire"
                            type="checkbox"
                            checked={this.state.grad_multumire}
                            onChange={this.handleInputChange}
                            />

                        </GridItem>
                    </CardBody>
                </Card>
            </GridContainer>
        );
    }
}
export default withStyles(style)(StepFormularGdprData);

