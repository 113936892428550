import axios from "axios/dist/axios";

export class WizardManager {
    constructor(props){
        this.STD_EXPIRE_CHECK_MINS = 10;
        this.loginManager = window.loginManager;
        this.wizardData = {};
    }

    resetWizardData(){
        this.wizardData = {};
    }

    setDataValue(key, value){
        this.wizardData[key] = value;
        this.storeWizardDataInSession();
    }

    storeWizardDataInSession(){
        sessionStorage.setItem('wizardData', JSON.stringify(this.wizardData));
    }

    loadWizardDataFromSession(){
        let wizardData = sessionStorage.getItem('wizardData');
        if (typeof wizardData === 'undefined' || wizardData ===  null || wizardData === ''){
            wizardData = "{}";
        }

        wizardData = JSON.parse(wizardData);
        this.wizardData = wizardData;
    }

    checkPartnerByCNP(cnpValue){
        let that = this;

        let promise = new Promise((resolve, reject) => {
                that.loginManager.checkToken().then(function () {
                axios.post(that.loginManager.config.wsUrl + '/api/v1/re/' + that.loginManager.userData.token + "/checkPartnerByCNP",
                    {
                        partnerId: cnpValue,
                    },
                    {
                        headers: {
                            ...that.loginManager.config.corsHeaders
                    }
            }).then(function (response) {
                    if (typeof response !== 'undefined' && response
                        && typeof response.data !== 'undefined' && response.data
                        && typeof response.data.result !== 'undefined' && response.data.result === 'OK'){

                        resolve(response.data);
                    } else {
                        var err = response.data.info;
                        if (typeof err === 'undfined' || !err){
                            err = 'Unable to load data.'
                        }

                        reject(err);
                    }
                }).catch(function (response) {
                    reject(response);
                });
            }).catch(function (err) {
                reject(err);
            });
    });

        return promise;
    }

    loadCrmCall(){

        let that = this;

        let promise = new Promise((resolve, reject) => {
            that.loginManager.checkToken().then(function () {
                axios.post(that.loginManager.config.wsUrl + '/api/v1/re/' + that.loginManager.userData.token + "/sendToCrm",
                    {
                        tip: that.wizardData.type,
                        info: that.wizardData
                    },
                    {
                        headers: {
                                    ...that.loginManager.config.corsHeaders
                                }
                    }).then(function (response) {
                            if (typeof response !== 'undefined' && response
                                && typeof response.data !== 'undefined' && response.data
                                && typeof response.data.result !== 'undefined' && response.data.result === 'OK'){

                                resolve(response.data);
                            } else {
                                var err = response.data.info;
                                if (typeof err === 'undfined' || !err){
                                    err = 'Unable to load data.'
                                }

                                reject(err);
                            }
                        }).catch(function (response) {
                            reject(response);
                        });
                }).catch(function (err) {
                reject(err);
            });
            });

            return promise;
            }

    loadPodCall(val){

        let that = this;

        let promise = new Promise((resolve, reject) => {
            that.loginManager.checkToken().then(function () {
                axios.post(that.loginManager.config.wsUrl + '/api/v1/re/' + that.loginManager.userData.token + "/checkPod",
                    {
                        tip: that.wizardData.type,
                        info: val
                    },
                    {
                        headers: {
                            ...that.loginManager.config.corsHeaders
                        }
                    }).then(function (response) {
                    if (typeof response !== 'undefined' && response
                        && typeof response.data !== 'undefined' && response.data
                        && typeof response.data.result !== 'undefined' && response.data.result === 'OK'){

                        resolve(response.data);
                    } else {
                        var err = response.data.info;
                        if (typeof err === 'undfined' || !err){
                            err = 'Unable to load data.'
                        }

                        reject(err);
                    }
                }).catch(function (response) {
                    reject(response);
                });
            }).catch(function (err) {
                reject(err);
            });
        });

        return promise;
    }

    loadLocationData(){
        let that = this;

        let promise = new Promise((resolve, reject) => {
            that.loginManager.checkToken().then(function () {
                axios.post(that.loginManager.config.wsUrl + '/api/v1/re/' + that.loginManager.userData.token + "/getReZoneOperare",
                    {
                        tip: that.wizardData.type
                    },
                    {
                        headers: {
                            ...that.loginManager.config.corsHeaders
                        }
                    }).then(function (response) {
                        if (typeof response !== 'undefined' && response
                            && typeof response.data !== 'undefined' && response.data
                            && typeof response.data.result !== 'undefined' && response.data.result === 'OK'){

                            resolve(response.data);
                        } else {
                            var err = response.data.info;
                            if (typeof err === 'undfined' || !err){
                                err = 'Unable to load data.'
                            }

                            reject(err);
                        }
                    }).catch(function (response) {
                        reject(response);
                    });
            }).catch(function (err) {
                reject(err);
            });
        });

        return promise;
    }

loadJudeteData(){
    let that = this;

    let promise = new Promise((resolve, reject) => {
        that.loginManager.checkToken().then(function () {
            axios.post(that.loginManager.config.wsUrl + '/api/v1/re/' + that.loginManager.userData.token + "/getReJudete",
                {
                    tip: that.wizardData.type
                },
                {
                    headers: {
            ...that.loginManager.config.corsHeaders
        }
    }).then(function (response) {
            if (typeof response !== 'undefined' && response
                && typeof response.data !== 'undefined' && response.data
                && typeof response.data.result !== 'undefined' && response.data.result === 'OK'){

                resolve(response.data);
            } else {
                var err = response.data.info;
                if (typeof err === 'undfined' || !err){
                    err = 'Unable to load data.'
                }

                reject(err);
            }
        }).catch(function (response) {
            reject(response);
        });
}).catch(function (err) {
    reject(err);
});
});

return promise;
}

    loadCategoriiData(){
        let that = this;

        let promise = new Promise((resolve, reject) => {
            that.loginManager.checkToken().then(function () {
                axios.post(that.loginManager.config.wsUrl + '/api/v1/re/' + that.loginManager.userData.token + "/getCategorii?idFisier=" + window.wizardManager.wizardData.offer.idFisier,
                    {
                        headers: {
                            ...that.loginManager.config.corsHeaders
                        }
                    }).then(function (response) {
                        if (typeof response !== 'undefined' && response
                            && typeof response.data !== 'undefined' && response.data){

                            resolve(response.data);
                        } else {
                            var err = response.data;
                            if (typeof err === 'undfined' || !err){
                                err = 'Unable to load data.'
                            }

                            reject(err);
                        }
                    }).catch(function (response) {
                        reject(response);
                    });
            }).catch(function (err) {
                reject(err);
            });
        });

        return promise;
    }

loadLocalitatiData(countyId){
    let that = this;

    let promise = new Promise((resolve, reject) => {
        that.loginManager.checkToken().then(function () {
            axios.post(that.loginManager.config.wsUrl + '/api/v1/re/' + that.loginManager.userData.token + "/getReLocalitate",
                {
                    tip: that.wizardData.type,
                    countyId: countyId
                },
                {
                    headers: {
            ...that.loginManager.config.corsHeaders
        }
    }).then(function (response) {
            if (typeof response !== 'undefined' && response
                && typeof response.data !== 'undefined' && response.data
                && typeof response.data.result !== 'undefined' && response.data.result === 'OK'){

                resolve(response.data);
            } else {
                var err = response.data.info;
                if (typeof err === 'undfined' || !err){
                    err = 'Unable to load data.'
                }

                reject(err);
            }
        }).catch(function (response) {
            reject(response);
        });
}).catch(function (err) {
    reject(err);
});
});

return promise;
}

loadStraziData(localityId){
    let that = this;

    let promise = new Promise((resolve, reject) => {
        that.loginManager.checkToken().then(function () {
            axios.post(that.loginManager.config.wsUrl + '/api/v1/re/' + that.loginManager.userData.token + "/getReStrazi",
                {
                    tip: that.wizardData.type,
                    localityId: localityId
                },
                {
                    headers: {
            ...that.loginManager.config.corsHeaders
        }
    }).then(function (response) {
            if (typeof response !== 'undefined' && response
                && typeof response.data !== 'undefined' && response.data
                && typeof response.data.result !== 'undefined' && response.data.result === 'OK'){

                resolve(response.data);
            } else {
                var err = response.data.info;
                if (typeof err === 'undfined' || !err){
                    err = 'Unable to load data.'
                }

                reject(err);
            }
        }).catch(function (response) {
            reject(response);
        });
}).catch(function (err) {
    reject(err);
});
});

return promise;
}
    loadOfferData(){
        let that = this;

        let promise = new Promise((resolve, reject) => {
            that.loginManager.checkToken().then(function () {
                axios.post(that.loginManager.config.wsUrl + '/api/v1/re/' + that.loginManager.userData.token + "/getOffers",
                    {
                        tip: that.wizardData.type,
                        location: that.wizardData.location
                    },
                    {
                        headers: {
                            ...that.loginManager.config.corsHeaders
                        }
                    }).then(function (response) {
                    if (typeof response !== 'undefined' && response
                        && typeof response.data !== 'undefined' && response.data
                        && typeof response.data.result !== 'undefined' && response.data.result === 'OK'){

                        resolve(response.data);
                    } else {
                        var err = response.data.info;
                        if (typeof err === 'undfined' || !err){
                            err = 'Unable to load data.'
                        }

                        reject(err);
                    }
                }).catch(function (response) {
                    reject(response);
                });
            }).catch(function (err) {
                reject(err);
            });
        });

        return promise;
    }

    loadDocumentData(){
        let that = this;

        let promise = new Promise((resolve, reject) => {
            that.loginManager.checkToken().then(function () {
                axios.post(that.loginManager.config.wsUrl + '/api/v1/re/' + that.loginManager.userData.token + "/getDocObligatoriiRe",
                    {
                        idFisier: that.wizardData.offer.idFisier
                    },
                    {
                        headers: {
                            ...that.loginManager.config.corsHeaders
                        }
                    }).then(function (response) {
                        if (typeof response !== 'undefined' && response
                            && typeof response.data !== 'undefined' && response.data
                            && typeof response.data.result !== 'undefined' && response.data.result === 'OK'){

                            resolve(response.data);
                        } else {
                            var err = response.data.info;
                            if (typeof err === 'undfined' || !err){
                                err = 'Unable to load data.'
                            }

                            reject(err);
                        }
                    }).catch(function (response) {
                        reject(response);
                    });
            }).catch(function (err) {
                reject(err);
            });
        });

        return promise;
    }

    loadSignDocumentData(){
        let that = this;

        let promise = new Promise((resolve, reject) => {
            that.loginManager.checkToken().then(function () {
                axios.post(that.loginManager.config.wsUrl + '/api/v1/re/' + that.loginManager.userData.token + "/getFisiereSemnaturaRe",
                    {
                        idFisier: that.wizardData.offer.idFisier
                    },
                    {
                        headers: {
                            ...that.loginManager.config.corsHeaders
                        }
                    }).then(function (response) {
                        if (typeof response !== 'undefined' && response
                            && typeof response.data !== 'undefined' && response.data
                            && typeof response.data.result !== 'undefined' && response.data.result === 'OK'){

                            resolve(response.data);
                        } else {
                            var err = response.data.info;
                            if (typeof err === 'undfined' || !err){
                                err = 'Unable to load data.'
                            }

                            reject(err);
                        }
                    }).catch(function (response) {
                        reject(response);
                    });
            }).catch(function (err) {
                reject(err);
            });
        });

        return promise;
    }

    getRegInfoObj(){
        let regInfoObj = {};
        regInfoObj.customer = window.wizardManager.wizardData.customer;
        regInfoObj.customer.straziData = undefined;
        regInfoObj.customer.judetData = undefined;
        regInfoObj.customer.localitatiData = undefined;
        regInfoObj.detaliiContractuale = window.wizardManager.wizardData.detaliiContractuale;
        regInfoObj.detaliiContractuale.judetData = undefined;
        regInfoObj.formularGdpr = window.wizardManager.wizardData.formularGdpr;
        regInfoObj.locConsum = window.wizardManager.wizardData.locConsum;
        regInfoObj.locConsum.judetData = undefined;
        regInfoObj.location = window.wizardManager.wizardData.location;
        regInfoObj.offer = window.wizardManager.wizardData.offer;
        regInfoObj.signDocuments = window.wizardManager.wizardData.signDocuments;
        regInfoObj.subscriptionType = window.wizardManager.wizardData.subscriptionType;
        regInfoObj.type = window.wizardManager.wizardData.type;
        regInfoObj.geoLocation = {};
        if (typeof window.wizardManager.wizardData.geoLocation !== 'undefined' && window.wizardManager.wizardData.geoLocation !== null) {
            regInfoObj.geoLocation.latitude = window.wizardManager.wizardData.geoLocation.latitude;
            regInfoObj.geoLocation.longitude = window.wizardManager.wizardData.geoLocation.longitude;
            regInfoObj.geoLocation.addr = window.wizardManager.wizardData.geoLocationAddr;
        }
        return regInfoObj;
    }

    getRegInfoB64(){
        let regInfoObj = this.getRegInfoObj();
        let regInfoJson = JSON.stringify(regInfoObj);

        return window.utf8_to_b64(regInfoJson);
    }

    getLocationInfo(){
        let that = this;

        let promise = new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(function(position){
                resolve(position.coords);
            }, function(err){
                reject(err);
            });
        });

        return promise;
    }

    getReverseGeocoding(lat, lng){
        let that = this;

        let promise = new Promise((resolve, reject) => {
            axios.get("https://eu1.locationiq.com/v1/reverse.php?key="+that.loginManager.config.geocodeKey+"&lat="+lat+"&lon="+lng+"&format=json",
                null,
                null
                ).then(function (response) {
                    if (typeof response !== 'undefined' && response
                        && typeof response.data !== 'undefined' && response.data
                        && typeof response.data.display_name !== 'undefined' && response.data.display_name){

                        resolve(response.data.display_name);
                    } else {
                        reject('Invalid response.');
                    }
                }).catch(function (response) {
                    reject(response);
                });
        });

        return promise;
    }

    getPreviewSignDownloadLink(id){
        let that = this;

        let promise = new Promise((resolve, reject) => {
            that.loginManager.checkToken().then(function () {
                axios.post(that.loginManager.config.wsUrl + '/api/v1/re/' + that.loginManager.userData.token + "/getFisierSemnaturaReLink/" + id,
                    {
                        regInfoB64: that.getRegInfoB64()
                    },
                    {
                        headers: {
                            ...that.loginManager.config.corsHeaders
                        }
                    }).then(function (response) {
                        if (typeof response !== 'undefined' && response
                            && typeof response.data !== 'undefined' && response.data
                            && typeof response.data.result !== 'undefined' && response.data.result === 'OK'){

                            resolve(response.data);
                        } else {
                            var err = response.data.info;
                            if (typeof err === 'undfined' || !err){
                                err = 'Unable to load response.'
                            }

                            reject(err);
                        }
                    }).catch(function (response) {
                        reject(response);
                    });
                }).catch(function (err) {
                    reject(err);
                });
        });

        return promise;
    }

    createReDocument(){
        let that = this;

        let promise = new Promise((resolve, reject) => {
            that.loginManager.checkToken().then(function () {
                const data = new FormData();

                if (typeof that.wizardData.documents !== 'undefined' && that.wizardData.documents){
                    Object.keys(that.wizardData.documents).forEach(function(id) {
                        data.append("file_"+id, that.wizardData.documents[id]);
                    });
                }

                data.append("regInfoB64", that.getRegInfoB64());

                axios.post(that.loginManager.config.wsUrl + '/api/v1/re/' + that.loginManager.userData.token + "/createReDocument",
                    data,
                    {
                        headers: {
                            ...that.loginManager.config.corsHeaders
                        }
                    }).then(function (response) {
                        if (typeof response !== 'undefined' && response
                            && typeof response.data !== 'undefined' && response.data
                            && typeof response.data.result !== 'undefined' && response.data.result === 'OK'){

                            resolve(response.data);
                        } else {
                            var err = response.data.info;
                            if (typeof err === 'undfined' || !err){
                                err = 'Unable to load response.'
                            }

                            reject(err);
                        }
                    }).catch(function (response) {
                        reject(response);
                    });
            }).catch(function (err) {
                reject(err);
            });
        });

        return promise;
    }

    scanOcr(file){
        let that = this;

        let promise = new Promise((resolve, reject) => {
            that.loginManager.checkToken().then(function () {
                const data = new FormData();

                if (typeof file !== 'undefined' && file !== null){
                    data.append("fileData", file);
                }

                axios.post(that.loginManager.config.ocrUrl + '/api/v1/file/4f7cc3bec3ac176ec388c2b0c3884b0bc2a7c3820b3f5c575ac3b0c3a248c2a72353e28098cb9cc396e280a2c38e5b4837/uploadForCIOcrScan',
                    data,
                    {
                        headers: {
                            ...that.loginManager.config.corsHeaders
                        }
                    }).then(function (response) {
                        if (typeof response !== 'undefined' && response
                            && typeof response.data !== 'undefined' && response.data
                            && typeof response.data.result !== 'undefined' && response.data.result === 'OK'){

                            resolve(response.data);
                        } else {
                            var err = response.data.info;
                            if (typeof err === 'undfined' || !err){
                                err = 'Unable to load response.'
                            }

                            reject(err);
                        }
                    }).catch(function (response) {
                        reject(response);
                    });
                }).catch(function (err) {
                    reject(err);
                });
            });

        return promise;
    }
}