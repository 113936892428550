import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import * as utils from "helper/utils.js";
import * as data from "helper/data.js";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardText from "components/Card/CardText.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import customSelectStyle from "assets/jss/restart-energy/customSelectStyle.jsx";
import customCheckboxRadioSwitch from "assets/jss/restart-energy/customCheckboxRadioSwitch.jsx";

import Button from "components/CustomButtons/Button.jsx";

import Gas from "@material-ui/icons/InvertColors";
import Electricity from "@material-ui/icons/WbIncandescent";

import {
  cardTitle,
  infoColor,
  successColor,
  grayColor,
  roseColor
} from "assets/jss/restart-energy.jsx";

const style = {
  cardText: {
    width: "100%",
    textAlign: "center"
  },
  cardTitle: {
    ...cardTitle,
    color: "#FFFFFF",
    textDecoration: "underline !important",
    marginTop: "0",
    width: "100%"
  },
  cardCategoryWhite: {
    margin: "0",
    color: "rgba(255, 255, 255, 0.8)",
    fontSize: ".875rem",
    width: "100%"
  },
  cardCategory: {
    color: "#000000",
    marginTop: "10px",
    textTransform: "uppercase",
    fontWeight: "800",
  },
  cardDescription:{
    padding: "0 15px"
  },
  icon: {
    color: "#333333",
    margin: "10px auto 0",
    width: "130px",
    height: "130px",
    border: "1px solid #E5E5E5",
    borderRadius: "50%",
    lineHeight: "174px",
    "& svg": {
      width: "55px",
      height: "55px"
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      width: "55px",
      fontSize: "55px"
    }
  },
  iconInfo: {
    color: infoColor
  },
  marginTop30: {
    marginTop: "30px"
  },
  iconSuccess: {
    color: successColor
  },
  iconRose: {
    color: roseColor
  },
  iconNotselected: {
    color: grayColor
  },
  testimonialIcon: {
    marginTop: "30px",
    "& svg": {
      width: "40px",
      height: "40px"
    }
  },
  cardTestimonialDescription: {
    fontStyle: "italic",
    color: "#999999"
  }
};

class StepSelectOffer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      offer: "",
      offerState: "",
      subscriptionType: "",
      bgColor: grayColor,
      data: data.offers(),
      utils: utils.utils()
    };
    this.loginManager = window.loginManager;
    this.wizardManger = window.wizardManager;
  }

  sendState() {
    return this.state;
  }

  reloadStateNext() {
    this.wizardManger.setDataValue("offer", null);
    this.wizardManger.setDataValue("subscriptionType", null);
    this.setState({ offer: "", offerData: null, offerState: "fail", subscriptionType: "", bgColor: grayColor });
  }

  loadOfferData() {
    let that = this;

    if (
      this.wizardManger.wizardData.step.stepId === "select_offer" &&
      typeof this.wizardManger.wizardData.type !== "undefined" &&
      this.wizardManger.wizardData.type &&
      typeof this.wizardManger.wizardData.location !== "undefined" &&
      this.wizardManger.wizardData.location
    ) {
      this.wizardManger
        .loadOfferData()
        .then(function(data) {
          that.loadingOfferData = false;
          that.setState({ offerData: data.offerList });
        })
        .catch(function(err) {
          that.loadingOfferData = false;
          alert(err);
        });
    }

    that.loadingOfferData = false;
  }

  getPreviewerLink(item){
    let req = {};
    req.id = item.idFisier;
    req.type = 'normal';
    req.userData = this.loginManager.userData;

    return this.loginManager.config.previewerUrl + "?data=" + window.utf8_to_b64(JSON.stringify(req));
  }

  handleChange = (event, id) => {
    let selected = {};

    for (let value of this.state.offerData) {
      if (value.id === id) {
        selected = value;
      }
    }

    this.wizardManger.setDataValue("offer", selected);
    this.wizardManger.setDataValue(
      "subscriptionType",
      selected.subscriptionType.toLowerCase()
    );
    this.setState({ offer: id });
    this.setState({ bgColor: roseColor });
    this.setState({ offerState: "success" });
    this.state.utils.triggerStepNext();
  };

  isValidated() {
    if (
      this.state.offerState === "success"
    ) {
      return true;
    } else {
      if (this.state.offerState !== "success") {
        return false;
      }
    }
    return false;
  }

  render() {
    const { classes } = this.props;

    if (!this.state.offerData) {
      if (!this.loadingOfferData) {
        this.loadingOfferData = true;
        this.loadOfferData();
      }

      return <div>Loading...</div>;
    }

    let that = this;

    let itemlist = this.state.offerData.map(function(item) {
      switch (item.tip) {
        case "gas":
          return (
            <GridItem xs={12} sm={12} lg={12}>
              <Card pricing>
                <CardBody
                  pricing
                  style={{ background: "#f3f3f3", border: "1px solid green" }}
                >
                  <h5 className={classes.cardCategory}>{item.nume}</h5>
                  <p className={classes.cardDescription}>{item.description}</p>
                  <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                  </div>

                  <Button
                    value="{item.id}"
                    color="info"
                    round
                    onClick={evt => that.handleChange(evt, item.id)}
                  >
                    Alege oferta
                  </Button>
                  <a href={that.getPreviewerLink(item)} target="_blank">
                  <Button
                    color="white"
                    round>
                    Vezi oferta
                  </Button>
                  </a>
                </CardBody>
              </Card>
            </GridItem>
          );
          break;
        case "electricity":
          return (
            <GridItem xs={12} sm={12} lg={12}>
              <Card pricing>
                <CardBody
                  pricing
                  style={{ background: "#f3f3f3", border: "1px solid green" }}
                >
                  <h5 className={classes.cardCategory}>{item.nume}</h5>
                  <p className={classes.cardDescription}>{item.description}</p>
                  <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                  </div>

                  <Button
                    value="{item.id}"
                    color="success"
                    round
                    onClick={evt => that.handleChange(evt, item.id)}
                  >
                   Alege oferta
                  </Button>
                  <a href={that.getPreviewerLink(item)} target="_blank">
                  <Button
                    color="white"
                    round>
                    Vezi oferta
                  </Button>
                  </a>
                </CardBody>
              </Card>
            </GridItem>
          );
          break;
      }
    });

    if (typeof itemlist === "undefined" || !itemlist || itemlist.length === 0) {
      return (
        <div>
          <h4 className={classes.infoText} />
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12} lg={10}>
              <GridContainer>
                <Card>
                  <CardHeader color="warning" text>
                    <CardText className={classes.cardText} color="warning">
                      <h4
                        style={{ width: "100%" }}
                        className={classes.cardTitleWhite}
                      >
                        Alege oferta
                      </h4>
                      <h4 className={classes.cardCategoryWhite}>
                        <i
                          style={{
                            fontSize: "10px",
                            marginRight: "1px",
                            color: "grey"
                          }}
                          className="fas fa-circle"
                        />
                        <i
                          style={{
                            fontSize: "10px",
                            marginRight: "1px",
                            color: "grey"
                          }}
                          className="fas fa-circle"
                        />
                        <i
                          style={{
                            fontSize: "10px",
                            marginRight: "1px",
                            color: "white"
                          }}
                          className="fas fa-circle"
                        />
                        <i
                          style={{
                            fontSize: "10px",
                            marginRight: "1px",
                            color: "grey"
                          }}
                          className="fas fa-circle"
                        />
                        <i
                          style={{
                            fontSize: "10px",
                            marginRight: "1px",
                            color: "grey"
                          }}
                          className="fas fa-circle"
                        />
                        <i
                          style={{
                            fontSize: "10px",
                            marginRight: "1px",
                            color: "grey"
                          }}
                          className="fas fa-circle"
                        />
                      </h4>
                    </CardText>
                  </CardHeader>
                  <CardBody>Nu s-au gasit oferte</CardBody>
                </Card>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </div>
      );
    }

    return (
      <div>
        <h4 className={classes.infoText} />
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12} lg={10}>
            <GridContainer>
              <Card>
                <CardHeader color="warning" text>
                  <CardText className={classes.cardText} color="warning">
                    <h4
                      style={{ width: "100%" }}
                      className={classes.cardTitleWhite}
                    >
                      Alege oferta
                    </h4>
                    <h4 className={classes.cardCategoryWhite}>
                      <i
                        style={{
                          fontSize: "10px",
                          marginRight: "1px",
                          color: "grey"
                        }}
                        className="fas fa-circle"
                      />
                      <i
                        style={{
                          fontSize: "10px",
                          marginRight: "1px",
                          color: "grey"
                        }}
                        className="fas fa-circle"
                      />
                      <i
                        style={{
                          fontSize: "10px",
                          marginRight: "1px",
                          color: "white"
                        }}
                        className="fas fa-circle"
                      />
                      <i
                        style={{
                          fontSize: "10px",
                          marginRight: "1px",
                          color: "grey"
                        }}
                        className="fas fa-circle"
                      />
                      <i
                        style={{
                          fontSize: "10px",
                          marginRight: "1px",
                          color: "grey"
                        }}
                        className="fas fa-circle"
                      />
                      <i
                        style={{
                          fontSize: "10px",
                          marginRight: "1px",
                          color: "grey"
                        }}
                        className="fas fa-circle"
                      />
                      <i
                        style={{
                          fontSize: "10px",
                          marginRight: "1px",
                          color: "grey"
                        }}
                        className="fas fa-circle"
                      />
                      <i
                        style={{
                          fontSize: "10px",
                          marginRight: "1px",
                          color: "grey"
                        }}
                        className="fas fa-circle"
                      />
                      <i
                        style={{
                          fontSize: "10px",
                          marginRight: "1px",
                          color: "grey"
                        }}
                        className="fas fa-circle"
                      />
                      <i
                          style={{
                              fontSize: "10px",
                              marginRight: "1px",
                              color: "grey"
                          }}
                          className="fas fa-circle"
                      />
                    </h4>
                  </CardText>
                </CardHeader>
                <CardBody>{itemlist}</CardBody>
              </Card>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(style)(StepSelectOffer);
