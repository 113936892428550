import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";
// core components
import Button from "components/CustomButtons/Button.jsx";

import defaultImage from "assets/img/image_placeholder2.png";
import defaultAvatar from "assets/img/placeholder.jpg";
import * as utils from "helper/utils.js";

class ImageUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage,
      fileName:"",
        utils: utils.utils()
    };
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.parent = props.advProps.parent;
    this.id = props.advProps.id
  }

  unlock(){
      var past = true;
      var images = document.getElementsByTagName("img");

      for(var i=0;i<images.length;i++){
          if(images[i].getAttribute("alt") != ""){
              this.state.utils.showStepNext();
          }
      }

  }

  tryLoadPreloadedImage(){
      if (typeof this.state.file === 'undefined' || this.state.file === null) {
          let that = this;
          if (typeof that.parent !== 'undefined' && that.parent
              && typeof that.id !== 'undefined' && that.id
              && typeof that.parent.getPreloadedImage === 'function') {
              let file = that.parent.getPreloadedImage(that.id);

              if (typeof file !== 'undefined' && file !== null) {
                  let reader = new FileReader();
                  reader.onloadend = () => {
                      this.setState({
                          file: file,
                          imagePreviewUrl: reader.result,
                          fileName:file.name
                      });

                      if (typeof that.parent !== 'undefined' && that.parent
                          && typeof that.id !== 'undefined' && that.id
                          && typeof that.parent.setOrChangeImage === 'function'){
                          that.parent.setOrChangeImage(that.id, file);
                      }
                  };
                  reader.readAsDataURL(file);
              }
          }
      }
  }

  handleImageChange(e) {
    let that = this;
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      let name = file.name;
        if(name.endsWith('.png')
        || name.endsWith('.jpg')
        || name.endsWith('.jpeg')
        || name.endsWith('.gif')
        || name.endsWith('.bmp')) {
            this.setState({
                file: file,
                imagePreviewUrl: reader.result,
                fileName: file.name
            });

            if (typeof that.parent !== 'undefined' && that.parent
                && typeof that.id !== 'undefined' && that.id
                && typeof that.parent.setOrChangeImage === 'function'){
                that.parent.setOrChangeImage(that.id, file);
            }
        }

      this.unlock();
    };
    reader.readAsDataURL(file);
  }
  handleSubmit(e) {
    e.preventDefault();
      // this.state.file is the file/image uploaded
    // in this function you can save the image (this.state.file) on form submit
    // you have to call it yourself
  }
  handleClick() {
    this.refs.fileInput.click();
  }
  handleRemove() {
    let that = this;
    this.setState({
      file: null,
      imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage
    });
    this.refs.fileInput.value = null;

    if (typeof that.parent !== 'undefined' && that.parent
        && typeof that.id !== 'undefined' && that.id
        && typeof that.parent.removeImage === 'function'){
        that.parent.removeImage(that.id);
    }
  }
  render() {
    let that = this;
    that.tryLoadPreloadedImage();

    var {
      avatar,
      addButtonProps,
      changeButtonProps,
      removeButtonProps,
      customProps
    } = this.props;
    return (
      <div className="fileinput text-center">
        <input type="file" onChange={this.handleImageChange} ref="fileInput" accept=".jpg,.jpeg,.png,.gif,.bmp,image/gif,image/jpeg,image/png,image/bmp" />
        <div className={"thumbnail" + (avatar ? " img-circle" : "")}>
          <img src={this.state.imagePreviewUrl} alt={this.state.fileName} onClick={() => this.handleClick()} />
        </div>
        <div>
          {this.state.file === null ? (
            <Button {...addButtonProps} onClick={() => this.handleClick()}>
              {avatar ? "Adauga imagine" : "Selectati imagine"}
            </Button>
          ) : (
            <span>
              <Button {...changeButtonProps} onClick={() => this.handleClick()}>
                Schimba
              </Button>
              {avatar ? <br /> : null}
              <Button
                {...removeButtonProps}
                onClick={() => this.handleRemove()}
              >
                <i className="fas fa-times" /> Sterge
              </Button>
            </span>
          )}
        </div>
      </div>
    );
  }
}

ImageUpload.propTypes = {
  avatar: PropTypes.bool,
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object,
  customProps: PropTypes.object
};

export default ImageUpload;
