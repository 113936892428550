import React from "react";
import PropTypes from "prop-types";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import CircularProgress from '@material-ui/core/CircularProgress';

import {cardTitle, container} from "assets/jss/restart-energy.jsx";

const style = theme => ({
    container: {
        ...container,
        zIndex: "4",
        [theme.breakpoints.down("sm")]: {
            paddingBottom: "100px"
        }
    },
    cardTitle: {
        ...cardTitle,
        color: "#000000"
    },
    textCenter: {
        textAlign: "center"
    },
    justifyContentCenter: {
        justifyContent: "center !important"
    },
    customButtonClass: {
        "&,&:focus,&:hover": {
            color: "#FFFFFF"
        },
        marginLeft: "5px",
        marginRight: "5px"
    },
    inputAdornment: {
        marginRight: "18px"
    },
    inputAdornmentIcon: {
        color: "#555"
    },
    cardHidden: {
        opacity: "0",
        transform: "translate3d(0, -60px, 0)"
    },
    cardHeader: {
        marginBottom: "20px"
    },
    socialLine: {
        padding: "0.9375rem 0"
    },
    err: {
        textDecoration: "italic",
        color: "red"
    },
    notifMessage: {
        display: "inline-block",
        overflow: "auto",
        marginLeft: "10px",
        marginTop:  "18px"
    }
});

class ChangePassView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cardAnimaton: "cardHidden",
            oldPassword: '',
            newPassword: '',
            newPassword2: '',
            err: [],
            progressIndicatorOn: false
        };

        this.loginManager = window.loginManager;
    }

    getProgressIndicator(){
        if (!this.state.progressIndicatorOn){
            return("");
        }

        return(
            <div>
                <CircularProgress
                    className={this.props.classes.progress}
                />

                <h4 className={this.props.classes.notifMessage}>Se schimba parola</h4>
            </div>
        );
    }

    updateUsername(evt) {
        this.setState({
            username: evt.target.value
        });
    }

    updateOldPassword(evt) {
        this.setState({
            oldPassword: evt.target.value
        });
    }

    updateNewPassword(evt) {
        this.setState({
            newPassword: evt.target.value
        });
    }


    updateNewPassword2(evt) {
        this.setState({
            newPassword2: evt.target.value
        });
    }

    change(evt) {
        let that = this;

        let validation = this.validate();
        if (validation.valid) {
            this.setState({
                err: []
            });
            this.doChange();
        } else {
            this.setState({
                err: validation.msg
            });
        }
    }

    validate() {
        let validation = {valid: true, msg: []};

        if (this.state.oldPassword.trim() === '') {
            validation.valid = false;
            validation.msg.push('Specifica parola veche.');
        }

        if (this.state.newPassword.trim() === '' || this.state.newPassword2.trim() === '') {
            validation.valid = false;
            validation.msg.push('Specifica parola noua de 2 ori.');
        }

        if (validation.valid) {
            if (this.state.newPassword !== this.state.newPassword2) {
                validation.valid = false;
                validation.msg.push('Parola noua nu coincide.');
            }
        }

        return validation;
    }

    doChange() {
        let that = this;
        that.setState({progressIndicatorOn: true});
        this.loginManager.doChangePassword(this.state.oldPassword, this.state.newPassword, this.state.newPassword2)
            .then(function (resp) {
                that.setState({progressIndicatorOn: false});
                alert("Parola schimbata!");
                that.props.history.push('/dashboard');
            })
            .catch(function (err) {
                that.setState({progressIndicatorOn: false});
                that.setState({
                    err: [err]
                });
            });
    }

    render() {
        let that = this;
        const {classes} = this.props;

        return (
            <GridContainer justify="center">

                <GridItem xs={12} sm={6} md={4}>
                    <form>
                        <CustomInput
                            labelText="Parola veche"
                            id="oldPassword"
                            inputComponent="password"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Icon className={classes.inputAdornmentIcon}>
                                            lock_outline
                                        </Icon>
                                    </InputAdornment>
                                ),
                                value: this.state.oldPassword,
                                type: "password",
                                onChange: (evt) => this.updateOldPassword(evt)
                            }}
                        />
                        <CustomInput
                            labelText="Parola noua"
                            id="newPassword"
                            inputComponent="password"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Icon className={classes.inputAdornmentIcon}>
                                            lock_outline
                                        </Icon>
                                    </InputAdornment>
                                ),
                                value: this.state.newPassword,
                                type: "password",
                                onChange: (evt) => this.updateNewPassword(evt)
                            }}
                        />
                        <CustomInput
                            labelText="Parola noua (repeta)"
                            id="newPassword2"
                            inputComponent="password"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Icon className={classes.inputAdornmentIcon}>
                                            lock_outline
                                        </Icon>
                                    </InputAdornment>
                                ),
                                value: this.state.newPassword2,
                                type: "password",
                                onChange: (evt) => this.updateNewPassword2(evt)
                            }}
                        />

                        <p>
                            <ul>
                                {this.state.err.map(function (name, index) {
                                    return <li className={classes.err} key={index}>{name}</li>;
                                })}
                            </ul>
                        </p>

                        <p>
                            {that.getProgressIndicator()}
                        </p>

                        <Button color="success" round size="lg" block onClick={evt => this.change(evt)}>
                            Schimba parola
                        </Button>
                    </form>
                </GridItem>
            </GridContainer>
        );
    }
}

ChangePassView.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(style)(ChangePassView);
