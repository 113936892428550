import React from "react";

export function utils() {
    return{
        gas:"gas",
        electric:"electric",
        signWidth: window.innerWidth - 10 + "px",
        signHeight: window.innerWidth/1.88 + "px",
        triggerStepNext:function () {
            setTimeout(function () {
                document.getElementById("theNextButton").click();
            },500)
        },
        showStepNext:function () {
            var element = document.getElementById("theNextButton");
            if(element) {
                element.classList.add("animated");
                element.classList.add("fadeInDownBig");
                element.removeAttribute("style");
            }
        },
        hideStepNext:function () {
            var element = document.getElementById("theNextButton");
            if(element){
                element.classList.remove("animated");
                element.classList.remove("fadeInDownBig");
                element.style.display="none";
            }

        },
        enableStepNext:function () {
            var element = document.getElementById("theNextButton");
            if(element){
                element.removeAttribute("disabled");
            }

        },
        disabledStepNext:function () {
            var element = document.getElementById("theNextButton");
            if(element){
                element.setAttribute("disabled","disabled");
            }

        },
        goPost: function(url, dat, id, target) {
            // daca nu are id, e math.random
            id = typeof id !== 'undefined' ? id : Math.floor(Math.random() * 3571);

            // default intra pe self
            target = typeof target !== 'undefined' ? target : "_self";

            let form_id = "post_form_" + id;

            let formElem = document.getElementById(form_id);
            if (typeof formElem !== 'undefined' && formElem) {
                formElem.parentNode.removeChild(formElem);
            }

            let form_string = '<form action="' + url + '" method="post" target = "' + target + '" id="' + form_id + '" name="' + form_id + '">';

            for (let key in dat) {
                let val = dat[key];

                form_string += '<input type="hidden" name="' + key + '" id="' + key + '_' + id + '" value="' + val + '" />';

                if (key === 'req') {
                    var secObj = dat[key];

                    for (let secKey in secObj) {
                        let secVal = secObj[secKey];
                        form_string += '<input type="hidden" name="' + secKey + '" id="' + secKey + '_'+id+'" value="' + secVal + '" />';
                    }
                }
            }

            form_string += '</form>';
            let form = this.createElementFromHTML(form_string);
            let bodyElem = document.getElementsByTagName('body')[0];
            bodyElem.appendChild(form);
            document.getElementById(form_id).submit();
        },
        createElementFromHTML: function(htmlString) {
            let div = document.createElement('div');
            div.innerHTML = htmlString.trim();
            return div.firstChild;
        }
    };
};

