import React from "react";

// core components
import Wizard from "views/Pages/Wizard.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Step1 from "./WizardSteps/selectType.jsx";
import Step2 from "./WizardSteps/selectLocation.jsx";
import Step3 from "./WizardSteps/selectOffer.jsx";
import Step4 from "./WizardSteps/formularGdprData.jsx";
import Step5 from "./WizardSteps/customerData.jsx";
import Step6 from "./WizardSteps/customerDocuments.jsx";
import Step7 from "./WizardSteps/locConsumData.jsx";
import Step8 from "./WizardSteps/detaliiContractualeData.jsx";
import Step9 from "./WizardSteps/customerSign.jsx";
import Step10 from "./WizardSteps/prezentareInformatiiClient.jsx";


class WizardView extends React.Component {
  render() {
    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={8}>
          <Wizard
            validate
            steps={[
              { stepName: "1", stepComponent: Step1, stepId: "selec_ttype" },
              { stepName: "2", stepComponent: Step2, stepId: "select_location" },
              { stepName: "3", stepComponent: Step3, stepId: "select_offer" },
              { stepName: "4", stepComponent: Step4, stepId: "formular_gdpr_data" },
              { stepName: "5", stepComponent: Step5, stepId: "customer_data" },
              { stepName: "6", stepComponent: Step6, stepId: "customer_documents" },
              { stepName: "7", stepComponent: Step7, stepId: "loc_consum_data" },
              { stepName: "8", stepComponent: Step8, stepId: "detalii_contractuale_data" },
              { stepName: "9", stepComponent: Step9, stepId: "customer_sign" },
              { stepName: "10", stepComponent: Step10, stepId: "prezentare_informatii_client" }

            ]}
            title=""
            subtitle=""
          />
        </GridItem>
      </GridContainer>
    );
  }
}

export default WizardView;
