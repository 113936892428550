import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardText from "components/Card/CardText.jsx";
import CardBody from "components/Card/CardBody.jsx";
import deburr from "lodash/deburr";
import Autosuggest from "react-autosuggest";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";

import * as utils from "helper/utils.js";

import customSelectStyle from "assets/jss/restart-energy/customSelectStyle.jsx";
import customCheckboxRadioSwitch from "assets/jss/restart-energy/customCheckboxRadioSwitch.jsx";
import CircularProgress from "@material-ui/core/CircularProgress";

let cantitateFilterDate = [];
let judetFilterDate = [];
let localitatiFilterDate = [];
let straziFilterDate = [];
let categoriiDate = [];

function renderInputComponent(inputProps) {
    const {
        classes, inputRef = () => {
        }, ref, ...other
    } = inputProps;
    return (
        <TextField
            fullWidth
            InputProps={{
                inputRef: node => {
                    ref(node);
                    inputRef(node);
                },
                classes: {
                    input: inputProps.clases.input
                }
            }}
            {...other}
        />
    );
}

function renderSuggestion(suggestion, {query, isHighlighted}) {
    const matches = match(suggestion.name, query);
    const parts = parse(suggestion.name, matches);

    if (
        typeof suggestion.addNew !== "undefined" &&
        suggestion.addNew[0].isAddNew
    ) {
        return (
            <MenuItem component="div">
                <div>
          <span style={{fontWeight: 500}}>
            [+] Adauga strada noua: {suggestion.addNew[1].value}
          </span>
                </div>
            </MenuItem>
        );
    }

    return (
        <MenuItem selected={isHighlighted} component="div">
            <div>
                {parts.map(
                    (part, index) =>
                        part.highlight ? (
                            <span key={String(index)} style={{fontWeight: 500}}>
                {part.text}
              </span>
                        ) : (
                            <strong key={String(index)} style={{fontWeight: 300}}>
                                {part.text}
                            </strong>
                        )
                )}
            </div>
        </MenuItem>
    );
}

function getSuggestions(value) {
    let that = this;
    const inputValue = deburr(value.trim()).toLowerCase();
    const inputLength = inputValue.length;
    let count = 0;
    return inputLength === 0
        ? []
        : cantitateFilterDate.filter(suggestion => {
            const keep =
                count < 5 &&
                suggestion.name.slice(0, inputLength).toLowerCase() === inputValue;

            if (keep) {
                count += 1;
            }
            return keep;
        });
}

function getSuggestionsCategorii(value) {
    let that = this;
    const inputValue = deburr(value.trim()).toLowerCase();
    const inputLength = inputValue.length;
    let count = 0;
    return inputLength === 0
        ? []
        : categoriiDate.filter(suggestion => {
            const keep =
                count < 5 &&
                suggestion.denumire.slice(0, inputLength).toLowerCase() === inputValue;

            if (keep) {
                count += 1;
            }
            return keep;
        });
}


function getSuggestionValue(suggestion) {
    //this.wizardManger.setDataValue("location", suggestion);
    //this.state.locationState = "success";
    if (
        typeof suggestion.addNew !== "undefined" &&
        suggestion.addNew[0].isAddNew
    ) {
        return suggestion.addNew[1].value;
    }

    return suggestion.name;
}

function loadCantitateLovData() {
    if (
        window.wizardManager.wizardData.offer != null &&
        window.wizardManager.wizardData.offer != "undefined" &&
        window.wizardManager.wizardData.offer.cantitateLov != null &&
        window.wizardManager.wizardData.offer.cantitateLov != "undefined"
    ) {
        let lngth = Object.keys(window.wizardManager.wizardData.offer.cantitateLov).length;
        let vals = window.wizardManager.wizardData.offer.cantitateLov;
        if (lngth > 0) {
            cantitateFilterDate = [];
            let i = 0;
            for (i = 0; i < lngth; i++) {
                cantitateFilterDate[i] = {name: vals[i].KEY};
            }
        }
    }
}

function loadCategoriiData() {
    categoriiDate = [];
    if (window.wizardManager.wizardData.step.stepId === "loc_consum_data" && typeof window.wizardManager.wizardData.offer !== "undefined" && categoriiDate.length === 0) {
        window.wizardManager.loadCategoriiData().then(function (data) {
            for (var i = 0; i < data.length; i++) {
                categoriiDate.push(data[i]);
            }
        })
            .catch(function (err) {
                alert(err);
            });
    }
}

const style = theme => ({
    cardText: {
        width: "100%",
        textAlign: "center"
    },
    infoText: {
        fontWeight: "300",
        margin: "10px 0 30px",
        textAlign: "center"
    },
    inputAdornmentIcon: {
        color: "#555"
    },
    inputAdornment: {
        position: "relative"
    },
    choiche: {
        textAlign: "center",
        cursor: "pointer",
        marginTop: "20px"
    },
    container: {
        position: "relative"
    },
    suggestionsContainerOpen: {
        position: "absolute",
        zIndex: 1,
        marginTop: theme.spacing.unit,
        left: 0,
        right: 0
    },
    suggestion: {
        display: "block"
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: "none"
    },
    divider: {
        height: theme.spacing.unit * 2
    },
    ...customSelectStyle,
    ...withStyles,
    ...customCheckboxRadioSwitch
});

function getSuggestionsJudet(value) {
    let that = this;
    const inputValue = deburr(value.trim()).toLowerCase();
    const inputLength = inputValue.length;
    let count = 0;
    return inputLength === 0
        ? []
        : judetFilterDate.filter(suggestion => {
            const keep =
                count < 5 &&
                suggestion.name.slice(0, inputLength).toLowerCase() === inputValue;

            if (keep) {
                count += 1;
            }
            return keep;
        });
}

function getSuggestionsLocalitati(value) {
    let that = this;
    const inputValue = deburr(value.trim()).toLowerCase();
    const inputLength = inputValue.length;
    let count = 0;
    return inputLength === 0
        ? []
        : localitatiFilterDate.filter(suggestion => {
            const keep =
                count < 5 &&
                suggestion.name.slice(0, inputLength).toLowerCase() === inputValue;

            if (keep) {
                count += 1;
            }
            return keep;
        });
}

function getSuggestionsStrazi(value) {
    let that = this;
    const inputValue = deburr(value.trim()).toLowerCase();
    const inputLength = inputValue.length;
    let count = 0;

    const suggestions = straziFilterDate.filter(suggestion => {
        const keep =
            count < 5 &&
            suggestion.name.slice(0, inputLength).toLowerCase() === inputValue;

        if (keep) {
            count += 1;
        }
        return keep;
    });
    return inputLength === 0
        ? []
        : suggestions.length === 0
            ? [
                {
                    addNew: [
                        {isAddNew: true},
                        {value: value.trim()},
                        {inputName: "l_strada"}
                    ]
                }
            ]
            : suggestions;
}

class StepLocConsumData extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            furnizor_actual: "",
            furnizor_actualState: "",
            adresa_loc_consum: "",
            adresa_loc_consumState: "",
            l_tara: "Romania",
            l_judet: "",
            singleJudetId: "",
            l_localitate: "",
            singleLocalitatiId: "",
            l_strada: "",
            singleStraziId: "",
            l_nr: "",
            l_sector: "",
            l_bloc: "",
            l_scara: "",
            l_tronson: "",
            l_etaj: "",
            l_apartament: "",
            l_cod_postal: "",
            l_judetState: "",
            l_localitateState: "",
            l_stradaState: "",
            cod_nlc: "",
            cod_nlcState: "",
            cod_pod: "",
            cod_podState: "",
            cod_clc_gn: "",
            cod_clc_gnState: "",
            cantitate_lunara_estimata: "",
            cantitate_lunara_estimataState: "",
            cantitate_anuala_estimata: "",
            cantitate_anuala_estimataState: "",
            folosesteAdresaDomiciliuLC: "",
            folosesteAdresaDomiciliuLCState: "",
            categorie_consumator: "",
            categorie_consumatorState: "",
            nr_contract_furnizare: "",
            suggestions: [],
            suggestionsCateg: [],
            suggestionsJudet: [],
            suggestionsLocalitati: [],
            suggestionsStrazi: [],
            unlockLocalitati: true,
            unlockStrazi: true,
            folosesteAltaAdresa: "",
            folosesteAltaAdresaState: "",
            showAdressFields: false,
            pod_verificat: true,
            pod_verificatErr: true,
            pod_verificatErrmsg: "",
            progressIndicatorOnPod: false,
            utils: utils.utils()
        };

        this.wizardManger = window.wizardManager;
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    loadJudeteData() {
        let that = this;

        judetFilterDate = [];

        if (
            this.wizardManger.wizardData.step.stepId === "loc_consum_data" &&
            typeof this.wizardManger.wizardData.type !== "undefined" &&
            this.wizardManger.wizardData.type
        ) {
            this.wizardManger
                .loadJudeteData()
                .then(function (data) {
                    that.loadingJudetData = false;
                    that.setState({judetData: data.judetList});
                    for (var i = 0; i < data.judetList.length; i++) {
                        judetFilterDate.push(data.judetList[i]);
                    }
                })
                .catch(function (err) {
                    that.loadingJudetData = false;
                    alert(err);
                });
        }

        that.loadingJudetData = false;
    }

    loadLocalitatiData() {
        let that = this;

        localitatiFilterDate = [];

        if (
            this.wizardManger.wizardData.step.stepId === "loc_consum_data" &&
            typeof this.wizardManger.wizardData.type !== "undefined" &&
            this.wizardManger.wizardData.type
        ) {
            this.wizardManger
                .loadLocalitatiData(this.state.singleJudetId)
                .then(function (data) {
                    that.loadingLocalitatiData = false;
                    that.setState({localitatiData: data.localitateList});
                    for (var i = 0; i < data.localitateList.length; i++) {
                        localitatiFilterDate.push(data.localitateList[i]);
                    }
                })
                .catch(function (err) {
                    that.loadingLocalitatiData = false;
                    alert(err);
                });
        }

        that.loadingLocalitatiData = false;
    }

    loadStraziData() {
        let that = this;

        straziFilterDate = [];

        if (
            this.wizardManger.wizardData.step.stepId === "loc_consum_data" &&
            typeof this.wizardManger.wizardData.type !== "undefined" &&
            this.wizardManger.wizardData.type
        ) {
            this.wizardManger
                .loadStraziData(this.state.singleLocalitatiId)
                .then(function (data) {
                    that.loadingStraziData = false;
                    that.setState({straziData: data.straziList});
                    for (var i = 0; i < data.straziList.length; i++) {
                        straziFilterDate.push(data.straziList[i]);
                    }
                })
                .catch(function (err) {
                    that.loadingStraziData = false;
                    alert(err);
                });
        }

        that.loadingStraziData = false;
    }

    sendState() {
        return this.state;
    }

    // function that returns true if value is email, false otherwise
    verifyEmail(value) {
        var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (emailRex.test(value)) {
            return true;
        }
        return false;
    }

    phoneNumber(value) {
        var phoneno = /^\d{10}$/;
        if (value.match(phoneno)) {
            return true;
        } else {
            return false;
        }
    }

    applyNewAdress(vfrom, val, vto) {
        this.state[vto + "tara"] = val[vfrom + "tara"];
        this.state[vto + "judet"] = val[vfrom + "judet"];
        this.state[vto + "localitate"] = val[vfrom + "localitate"];
        this.state[vto + "strada"] = val[vfrom + "strada"];
        this.state[vto + "nr"] = val[vfrom + "nr"];
        this.state[vto + "sector"] = val[vfrom + "sector"];
        this.state[vto + "bloc"] = val[vfrom + "bloc"];
        this.state[vto + "scara"] = val[vfrom + "scara"];
        this.state[vto + "tronson"] = val[vfrom + "tronson"];
        this.state[vto + "etaj"] = val[vfrom + "etaj"];
        this.state[vto + "apartament"] = val[vfrom + "apartament"];
        this.state[vto + "cod_postal"] = val[vfrom + "cod_postal"];
        this.state["singleLocalitatiId"] = val["singleLocalitatiId"];
        this.state["singleStraziId"] = val["singleStraziId"];
        this.state["singleJudetId"] = val["singleJudetId"];
        this.setAdress();
    }

    resetAdress(vto) {
        this.state.adresa_loc_consum = "";
        this.state[vto + "tara"] = "";
        this.state[vto + "judet"] = "";
        this.state[vto + "localitate"] = "";
        this.state[vto + "strada"] = "";
        this.state[vto + "nr"] = "";
        this.state[vto + "sector"] = "";
        this.state[vto + "bloc"] = "";
        this.state[vto + "scara"] = "";
        this.state[vto + "tronson"] = "";
        this.state[vto + "etaj"] = "";
        this.state[vto + "apartament"] = "";
        this.state[vto + "cod_postal"] = "";
        this.state["singleLocalitatiId"] = "";
        this.state["singleStraziId"] = "";
        this.state["singleJudetId"] = "";
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        const nameSec = value == true ? "false" : "true";

        if (name == "folosesteAdresaDomiciliuLC") {
            this.resetAdress("l_");
            this.applyNewAdress("d_", window.wizardManager.wizardData.customer, "l_");
            this.state.folosesteAdresaDomiciliuLCState =
                value == true ? "success" : "error";
            this.setState({
                ["folosesteAltaAdresa"]: false
            });
            this.state.folosesteAdresaDomiciliuState =
                value == true ? "success" : "error";
            this.state.adresa_loc_consumState = value == true ? "success" : "error";
        }
        if (name == "folosesteAltaAdresa") {
            this.setState({
                ["folosesteAdresaDomiciliuLC"]: false
            });
            this.state.folosesteAdresaDomiciliuLCState =
                value == true ? "success" : "error";
            this.state.adresa_loc_consumState = value == true ? "success" : "error";
        }

        this.state.showAdressFields = false;
        if (value == true && name == "folosesteAltaAdresa") {
            this.resetAdress("l_");
            this.state.l_tara = "Romania";
            this.state.showAdressFields = true;
            this.state.adresa_loc_consumState = value == true ? "success" : "error";
        }

        this.setState({
            [name]: value
        });
    }

    realNumber(value) {
        return !isNaN(parseFloat(value)) && isFinite(value);
    }

    // function that verifies if a string has a given length or not
    verifyLength(value, length) {
        if (value.length >= length) {
            return true;
        }
        return false;
    }

    change(event, stateName, type, stateNameEqualTo) {
        this.setState({[stateName]: event.target.value});
        switch (type) {
            case "length":
                if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                    this.setState({[stateName + "State"]: "success"});
                } else {
                    this.setState({[stateName + "State"]: "error"});
                }
                break;
            case "cod_pod":
                this.state.pod_verificat = true;
                if (window.wizardManager.wizardData.type == "electricity") {
                    if (event.target.value) {
                        this.setState({[stateName + "State"]: "success"});
                    } else {
                        this.setState({[stateName + "State"]: "error"});
                    }
                }
                break;
            case "cod_clc_gn":
                this.state.pod_verificat = true;
                if (window.wizardManager.wizardData.type == "gas") {
                    if (event.target.value) {
                        this.setState({[stateName + "State"]: "success"});
                    } else {
                        this.setState({[stateName + "State"]: "error"});
                    }
                }
                break;
            case "cantitate":
                if (this.realNumber(event.target.value)) {
                    this.setState({[stateName + "State"]: "success"});
                } else {
                    this.setState({[stateName + "State"]: "error"});
                }
                break;
            case "mandatory":
                if (this.mandatory(event.target.value)) {
                    this.setState({[stateName + "State"]: "success"});
                } else {
                    this.setState({[stateName + "State"]: "error"});
                }
                break;
            default:
                break;
        }
    }

    reloadState() {
        this.state.pod_verificat = true;
        this.wizardManger.setDataValue("locConsum", null);
        this.setState({locConsum: "", locConsumData: null});
    }

    mandatory(value) {
        return value !== "" && value !== null ? true : false;
    }

    setAdress() {
        this.state.adresa_loc_consum = "";
        this.checkNullAndAdd("Tara", this.state.l_tara);
        this.checkNullAndAdd("Judet", this.state.l_judet);
        this.checkNullAndAdd("Localitate", this.state.l_localitate);
        this.checkNullAndAdd("Str.", this.state.l_strada);
        this.checkNullAndAdd("Nr.", this.state.l_nr);
        this.checkNullAndAdd("Sec.", this.state.l_sector);
        this.checkNullAndAdd("Bl.", this.state.l_bloc);
        this.checkNullAndAdd("Sc.", this.state.l_scara);
        this.checkNullAndAdd("Trons.", this.state.l_tronson);
        this.checkNullAndAdd("Et.", this.state.l_etaj);
        this.checkNullAndAdd("Ap.", this.state.l_apartament);
        this.checkNullAndAdd("Cod pos.", this.state.l_cod_postal);
    }

    checkNullAndAdd(name, val) {
        if (val !== "" && val !== null) {
            if (
                !this.state.adresa_loc_consum.includes(",") &&
                Object.keys(this.state.adresa_loc_consum).length == 0
            ) {
                this.state.adresa_loc_consum = name + " " + val;
            } else {
                this.state.adresa_loc_consum =
                    this.state.adresa_loc_consum + ", " + name + " " + val;
            }
        }
    }

    continue() {
        if (window.wizardManager.wizardData.type == "electricity") {
            if (this.state.cod_podState === "success") {
                this.setAdress();
                this.wizardManger.setDataValue("locConsum", this.state);
                this.state.utils.triggerStepNext();
            }
        }
        if (window.wizardManager.wizardData.type == "gas") {
            if (this.state.cod_clc_gnState === "success") {
                this.setAdress();
                this.wizardManger.setDataValue("locConsum", this.state);
                this.state.utils.triggerStepNext();
            }
        }
    }

    allMandatoryValid() {
        if (
            this.state.l_judetState === "success" &&
            this.state.l_localitateState === "success" &&
            this.state.l_stradaState === "success"
        ) {
            return true;
        }
        return false;
    }

    getValueByKey() {
        let lngth = Object.keys(this.wizardManger.wizardData.offer.cantitateLov)
            .length;
        let vals = this.wizardManger.wizardData.offer.cantitateLov;
        let i = 0;
        for (i = 0; i < lngth; i++) {
            if (vals[i].KEY == this.state.cantitate_lunara_estimata) {
                return vals[i].VALUE;
            }
        }
    }

    checkValidAdress() {
        if (this.state.showAdressFields) {
            let ret =
                this.state.l_judetState === "success" &&
                this.state.l_localitateState === "success" &&
                this.state.l_stradaState === "success";
            if (ret) {
                if (this.state.folosesteAltaAdresaState !== "success") {
                    this.setState({folosesteAltaAdresaState: "error"});
                } else {
                    this.setState({folosesteAltaAdresaState: "success"});
                }
                return true;
            }
            return false;
        } else {
            if (this.state.folosesteAdresaDomiciliuLCState == "success") {
                return true;
            } else {
                if (this.state.folosesteAdresaDomiciliuLCState !== "success") {
                    this.setState({folosesteAdresaDomiciliuLCState: "error"});
                } else {
                    this.setState({folosesteAdresaDomiciliuLCState: "success"});
                }
            }
        }
        return false;
    }

    checkPodClc() {
        let ret = true;
        let parameter = "";
        let name = "";
        let that = this;
        that.errMsg = "";
        that.setState({progressIndicatorOnPod: true});

        if (window.wizardManager.wizardData.type == "electricity") {
            parameter = this.state.cod_pod;
            name = "POD";
        }
        if (window.wizardManager.wizardData.type == "gas") {
            parameter = this.state.cod_clc_gn;
            name = "CLC";
        }
        this.state.pod_verificat = false;
        let processed = false;

        this.wizardManger
            .loadPodCall(parameter)
            .then(function (data) {
                that.setState({progressIndicatorOnPod: false});
                processed = true;
                if (data.exists == "true") {
                    ret = false;
                    that.state.pod_verificatErr = false;
                    that.state.pod_verificatErrmsg = "Codul " + name + " exista deja!";
                } else {
                    that.state.pod_verificatErr = true;
                    that.state.pod_verificatErrmsg = "";
                }
                that.state.pod_verificat = true;
            })
            .catch(function (err) {
                processed = true;
                that.setState({progressIndicatorOnPod: false});
                alert("A avut loc o eroare: " + err);
            });

        return ret;
    }

    isValidated() {
        let that = this;
        that.errMsg = "";
        if (window.wizardManager.wizardData.type == "electricity") {
            if (
                this.state.pod_verificatErr &&
                this.state.pod_verificat &&
                this.checkValidAdress() &&
                this.state.cod_podState === "success" &&
                this.state.cantitate_lunara_estimataState === "success" &&
                this.state.furnizor_actualState === "success"
            ) {
                this.setAdress();
                //setarea pretului in functie de selectia cantitatii
                if (this.wizardManger.wizardData.offer.cantitateLov != null) {
                    if (
                        Object.keys(this.wizardManger.wizardData.offer.cantitateLov)
                            .length > 0 &&
                        window.wizardManager.wizardData.subscriptionType == "fix"
                    ) {
                        let val = this.getValueByKey();
                        if (Object.keys(this.state.cantitate_lunara_estimata).length > 0) {
                            this.wizardManger.wizardData.offer.price = val.VALUE;
                            this.wizardManger.wizardData.offer.categ = val.CATEG;
                        }
                        if (Object.keys(this.state.cantitate_anuala_estimata).length > 0) {
                            this.wizardManger.wizardData.offer.price = val.VALUE;
                            this.wizardManger.wizardData.offer.categ = val.CATEG;
                        }
                    }
                }
                this.wizardManger.setDataValue("locConsum", this.state);
                return true;
            } else {
                if (this.state.cod_podState !== "success") {
                    this.setState({cod_podState: "error"});
                }
                if (this.state.cantitate_lunara_estimataState !== "success") {
                    that.errMsg = "Cantitatea nu se regaseste in lista de valori!";
                    this.setState({cantitate_lunara_estimataState: "error"});
                }
                if (this.state.furnizor_actualState !== "success") {
                    this.setState({furnizor_actualState: "error"});
                }
                if (this.state.l_judetState !== "success") {
                    this.setState({l_judetState: "error"});
                }
                if (this.state.l_localitateState !== "success") {
                    this.setState({l_localitateState: "error"});
                }
                if (!this.state.pod_verificatErr) {
                    this.errMsg = this.state.pod_verificatErrmsg;
                }
                if (!this.state.pod_verificat) {
                    this.errMsg = "Va rugam verificati codul pod/clc!";
                }
                if (this.state.l_stradaState !== "success") {
                    this.setState({l_stradaState: "error"});
                }
                if (this.state.adresa_loc_consumState !== "success") {
                    this.setState({adresa_loc_consumState: "error"});
                } else {
                    this.setState({adresa_loc_consumState: "success"});
                }
            }
        }
        if (window.wizardManager.wizardData.type == "gas") {
            if (
                this.state.pod_verificatErr &&
                this.state.pod_verificat &&
                this.checkValidAdress() &&
                this.state.cod_clc_gnState === "success" &&
                this.state.furnizor_actualState === "success" &&
                ((this.state.cantitate_lunara_estimataState === "success" &&
                    window.wizardManager.wizardData.subscriptionType === "fix") ||
                    (this.state.cantitate_anuala_estimataState === "success" &&
                        this.state.categorie_consumatorState === "success" &&
                        window.wizardManager.wizardData.subscriptionType === "go"))
            ) {
                this.setAdress();
                //setarea pretului in functie de selectia cantitatii
                if (this.wizardManger.wizardData.offer.cantitateLov != null) {
                    if (
                        Object.keys(this.wizardManger.wizardData.offer.cantitateLov)
                            .length > 0 &&
                        window.wizardManager.wizardData.subscriptionType == "fix"
                    ) {
                        if (Object.keys(this.state.cantitate_lunara_estimata).length > 0) {
                            for (let i = 0; i < this.wizardManger.wizardData.offer.cantitateLov.length; i++) {
                                if (this.state.cantitate_lunara_estimata == this.wizardManger.wizardData.offer.cantitateLov[i].KEY) {
                                    this.wizardManger.wizardData.offer.price = this.wizardManger.wizardData.offer.cantitateLov[i].VALUE;
                                    this.wizardManger.wizardData.offer.categ = this.wizardManger.wizardData.offer.cantitateLov[i].CATEG;
                                }
                            }
                        }
                        if (Object.keys(this.state.cantitate_anuala_estimata).length > 0) {
                            for (let i = 0; i < this.wizardManger.wizardData.offer.cantitateLov.length; i++) {
                                if (this.state.cantitate_anuala_estimata == this.wizardManger.wizardData.offer.cantitateLov[i].KEY) {
                                    this.wizardManger.wizardData.offer.price = this.wizardManger.wizardData.offer.cantitateLov[i].VALUE;
                                    this.wizardManger.wizardData.offer.categ = this.wizardManger.wizardData.offer.cantitateLov[i].CATEG;
                                }
                            }
                        }
                    }
                }
                this.wizardManger.setDataValue("locConsum", this.state);
                return true;
            } else {
                if (this.state.cod_clc_gnState !== "success") {
                    this.setState({cod_clc_gnState: "error"});
                }
                if (
                    this.state.cantitate_lunara_estimataState !== "success" &&
                    window.wizardManager.wizardData.subscriptionType == "fix"
                ) {
                    that.errMsg = "Cantitatea nu se regaseste in lista de valori!";
                    this.setState({cantitate_lunara_estimataState: "error"});
                }
                if (!this.state.pod_verificatErr) {
                    this.errMsg = this.state.pod_verificatErrmsg;
                }
                if (!this.state.pod_verificat) {
                    this.errMsg = "Va rugam verificati codul pod/clc!";
                }
                if (
                    this.state.cantitate_anuala_estimataState !== "success" &&
                    window.wizardManager.wizardData.subscriptionType == "go"
                ) {
                    that.errMsg = "Cantitatea nu se regaseste in lista de valori!";
                    this.setState({cantitate_anuala_estimataState: "error"});
                }
                if (this.state.furnizor_actualState !== "success") {
                    this.setState({furnizor_actualState: "error"});
                }
                if (this.state.l_judetState !== "success") {
                    this.setState({l_judetState: "error"});
                }
                if (this.state.l_localitateState !== "success") {
                    this.setState({l_localitateState: "error"});
                }
                if (this.state.l_stradaState !== "success") {
                    this.setState({l_stradaState: "error"});
                }
                if (this.state.adresa_loc_consumState !== "success") {
                    this.setState({adresa_loc_consumState: "error"});
                } else {
                    this.setState({adresa_loc_consumState: "success"});
                }
                if (this.state.categorie_consumatorState !== "success") {
                    this.setState({categorie_consumatorState: "error"});
                }
            }
            return false;
        }
    }

    handleSuggestionsFetchRequested = ({value}) => {
        this.setState({
            suggestions: getSuggestions(value)
        });
    };

    handleSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    handleSuggestionsFetchRequestedCateg = ({value}) => {
        this.setState({
            suggestions: getSuggestionsCategorii(value)
        });
    };

    handleSuggestionsClearRequestedCateg = () => {
        this.setState({
            suggestions: []
        });
    };

    handleSuggestionsFetchRequestedJudet = ({value}) => {
        this.setState({
            suggestionsJudet: getSuggestionsJudet(value)
        });
    };

    handleSuggestionsClearRequestedJudet = () => {
        this.setState({
            suggestionsJudet: []
        });
    };

    handleSuggestionsFetchRequestedLocalitati = ({value}) => {
        this.setState({
            suggestionsLocalitati: getSuggestionsLocalitati(value)
        });
    };

    handleSuggestionsClearRequestedLocalitati = () => {
        this.setState({
            suggestionsLocalitati: []
        });
    };

    handleSuggestionsFetchRequestedStrazi = ({value}) => {
        this.setState({
            suggestionsStrazi: getSuggestionsStrazi(value)
        });
    };

    handleSuggestionsClearRequestedStrazi = () => {
        this.setState({
            suggestionsStrazi: []
        });
    };

    onSuggestionSelectedStrazi = (event, {suggestion}) => {
        if (
            typeof suggestion.addNew !== "undefined" &&
            suggestion.addNew[0].isAddNew
        ) {
            this.state[suggestion.addNew[2].inputName + "State"] = "success";
            this.state.l_cod_postalState = "error";
            this.state.l_cod_postal = "";
            //console.log('Add new:', suggestion.addNew[1].value);
        }
    };

    handleChange = name => (event, {newValue}) => {
        this.setState({
            [name]: newValue
        });

        if (
            cantitateFilterDate != "undefined" &&
            Object.keys(cantitateFilterDate).length > 0 &&
            (name == "cantitate_lunara_estimata" ||
                name == "cantitate_anuala_estimata")
        ) {
            let bol = false;
            let lngth = Object.keys(cantitateFilterDate).length;
            let i = 0;
            for (i = 0; i < lngth; i++) {
                if (cantitateFilterDate[i].name == newValue) {
                    bol = true;
                }
            }
            if (bol) {
                this.setState({[name + "State"]: "success"});
            } else {
                this.setState({[name + "State"]: "error"});
            }
        }
        if (
            judetFilterDate != "undefined" &&
            Object.keys(judetFilterDate).length > 0 &&
            name == "l_judet"
        ) {
            this.setState({
                [name]: newValue
            });
            var bol = false;
            var lngth = Object.keys(judetFilterDate).length;
            var i = 0;
            this.state.unlockLocalitati = true;
            this.state.unlockStrazi = true;
            this.state.l_localitate = "";
            this.state.l_strada = "";
            this.state.singleLocalitatiId = "";
            this.state.singleStraziId = "";
            for (i = 0; i < lngth; i++) {
                if (judetFilterDate[i].name == newValue) {
                    bol = true;
                    this.state.unlockLocalitati = false;
                    this.state.singleJudetId = judetFilterDate[i].countyId;
                    this.loadLocalitatiData();
                }
            }
            if (bol) {
                this.setState({[name + "State"]: "success"});
            } else {
                this.setState({[name + "State"]: "error"});
            }
            return;
        }
        if (
            localitatiFilterDate != "undefined" &&
            localitatiFilterDate.length > 0 &&
            name == "l_localitate"
        ) {
            this.setState({
                [name]: newValue
            });
            let bol = false;
            let lngth = localitatiFilterDate.length;
            let i = 0;
            this.state.unlockStrazi = true;
            this.state.l_strada = "";
            this.state.singleLocalitatiId = "";
            this.state.singleStraziId = "";
            for (i = 0; i < lngth; i++) {
                if (localitatiFilterDate[i].name == newValue) {
                    this.state.unlockStrazi = false;
                    this.state.singleLocalitatiId = localitatiFilterDate[i].localityId;
                    this.loadStraziData();
                    bol = true;
                }
            }
            if (bol) {
                this.setState({[name + "State"]: "success"});
            } else {
                this.setState({[name + "State"]: "error"});
            }
            return;
        }

        if (
            straziFilterDate != "undefined" &&
            straziFilterDate.length > 0 &&
            name == "l_strada"
        ) {
            this.setState({
                [name]: newValue
            });
            let bol = false;
            let lngth = straziFilterDate.length;
            let i = 0;
            this.state.singleStraziId = "";
            for (i = 0; i < lngth; i++) {
                if (straziFilterDate[i].name == newValue) {
                    bol = true;
                    this.state.singleStraziId = straziFilterDate[i].streetId;
                    this.state.l_cod_postal = straziFilterDate[i].zipCode;
                }
            }
            if (bol) {
                this.setState({[name + "State"]: "success"});
            } else {
                this.setState({[name + "State"]: "error"});
            }
            return;
        }
        if (
            categoriiDate != "undefined" &&
            Object.keys(categoriiDate).length > 0 &&
            name == "categorie_consumator"
        ) {
            var price;
            let bol = false;
            let lngth = Object.keys(categoriiDate).length;
            let i = 0;
            for (i = 0; i < lngth; i++) {
                if (categoriiDate[i].denumire.toLowerCase() == newValue.toLowerCase()) {
                    bol = true;
                    price = categoriiDate[i].pret;
                }
            }
            if (bol) {
                this.setState({[name + "State"]: "success"});
                window.wizardManager.wizardData.offer.categ = newValue.toUpperCase();
                window.wizardManager.wizardData.offer.price = price;
            } else {
                this.setState({[name + "State"]: "error"});
                window.wizardManager.wizardData.offer.categ = "";
                window.wizardManager.wizardData.offer.price = "";
            }
        }
    };

    getProgressIndicator() {
        if (!this.state.progressIndicatorOnPod) {
            return "";
        }

        return (
            <div>
                <CircularProgress className={this.props.classes.progress}/>

                <h4 className={this.props.classes.notifMessage}>
                    Se verifica codul POD/CLC
                </h4>
            </div>
        );
    }

    render() {
        loadCantitateLovData();
        loadCategoriiData();


        const autosuggestProps = {
            renderInputComponent,
            suggestions: this.state.suggestions,
            onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
            onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
            getSuggestionValue,
            utils: utils.utils(),
            wizardManger: window.wizardManager,
            state: this.state,
            renderSuggestion
        };

        const autosuggestPropsCateg = {
            renderInputComponent,
            suggestions: this.state.suggestionsCateg,
            onSuggestionsFetchRequested: this.handleSuggestionsFetchRequestedCateg,
            onSuggestionsClearRequested: this.handleSuggestionsClearRequestedCateg,
            getSuggestionValue,
            utils: utils.utils(),
            wizardManger: window.wizardManager,
            state: this.state,
            renderSuggestion
        };

        if (!this.state.judetData) {
            if (!this.loadingJudetData) {
                this.loadingJudetData = true;
                this.loadJudeteData();
            }

            return <div>Loading...</div>;
        }

        let that = this;

        const {classes} = this.props;
        return (
            <GridContainer justify="center">
                <Card>
                    <CardHeader color="warning" text>
                        <CardText className={classes.cardText} color="warning">
                            <h4 style={{width: "100%"}} className={classes.cardTitleWhite}>
                                Informatii loc consum
                            </h4>
                            <h4 className={classes.cardCategoryWhite}>
                                <i
                                    style={{
                                        fontSize: "10px",
                                        marginRight: "1px",
                                        color: "rgba(255,255,255,0.3)"
                                    }}
                                    className="fas fa-circle"
                                />
                                <i
                                    style={{
                                        fontSize: "10px",
                                        marginRight: "1px",
                                        color: "rgba(255,255,255,0.3)"
                                    }}
                                    className="fas fa-circle"
                                />
                                <i
                                    style={{
                                        fontSize: "10px",
                                        marginRight: "1px",
                                        color: "rgba(255,255,255,0.3)"
                                    }}
                                    className="fas fa-circle"
                                />
                                <i
                                    style={{
                                        fontSize: "10px",
                                        marginRight: "1px",
                                        color: "rgba(255,255,255,0.3)"
                                    }}
                                    className="fas fa-circle"
                                />
                                <i
                                    style={{
                                        fontSize: "10px",
                                        marginRight: "1px",
                                        color: "rgba(255,255,255,0.3)"
                                    }}
                                    className="fas fa-circle"
                                />
                                <i
                                    style={{
                                        fontSize: "10px",
                                        marginRight: "1px",
                                        color: "rgba(255,255,255,0.3)"
                                    }}
                                    className="fas fa-circle"
                                />
                                <i
                                    style={{
                                        fontSize: "10px",
                                        marginRight: "1px",
                                        color: "rgba(255,255,255,1)"
                                    }}
                                    className="fas fa-circle"
                                />
                                <i
                                    style={{
                                        fontSize: "10px",
                                        marginRight: "1px",
                                        color: "rgba(255,255,255,0.3)"
                                    }}
                                    className="fas fa-circle"
                                />
                                <i
                                    style={{
                                        fontSize: "10px",
                                        marginRight: "1px",
                                        color: "rgba(255,255,255,0.3)"
                                    }}
                                    className="fas fa-circle"
                                />
                                <i
                                    style={{
                                        fontSize: "10px",
                                        marginRight: "1px",
                                        color: "rgba(255,255,255,0.3)"
                                    }}
                                    className="fas fa-circle"
                                />
                            </h4>
                        </CardText>
                    </CardHeader>
                    <CardBody>
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                            <CustomInput
                                success={this.state.furnizor_actualState === "success"}
                                error={this.state.furnizor_actualState === "error"}
                                labelText={
                                    <span>
                    Furnizor actual <small>(obligatoriu)</small>
                  </span>
                                }
                                id="furnizor_actual"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    onChange: event =>
                                        that.change(event, "furnizor_actual", "mandatory")
                                }}
                            />

                            <CustomInput
                                success={this.state.adresa_loc_consumState === "success"}
                                error={this.state.adresa_loc_consumState === "error"}
                                labelText={<span>Adresa loc consum</span>}
                                inputProps={{
                                    disabled: "true"
                                }}
                                formControlProps={{
                                    fullWidth: true
                                }}
                            />
                            <br/>
                            <br/>
                            <input
                                name="folosesteAdresaDomiciliuLC"
                                type="checkbox"
                                checked={this.state.folosesteAdresaDomiciliuLC}
                                onChange={this.handleInputChange}
                            />
                            <label>Folositi adresa de domiciliu</label>
                            <br/>
                            <br/>
                            <input
                                name="folosesteAltaAdresa"
                                type="checkbox"
                                checked={this.state.folosesteAltaAdresa}
                                onChange={this.handleInputChange}
                            />
                            <label>Introduceti alta adresa:</label>

                            {togleAdressFields(
                                this,
                                this.state.showAdressFields,
                                classes,
                                that
                            )}

                            {getEEGNFields(window.wizardManager.wizardData.type, that)}
                            <GridItem xs={12} sm={12} md={12}>
                                <Button
                                    fullWidth="true"
                                    icon=""
                                    value="true"
                                    color="success"
                                    round
                                    inputProps={{
                                        onClick: event => this.checkPodClc()
                                    }}
                                >
                                    <i className="fas fa-camera"/>
                                    Verifica POD/CLC
                                </Button>
                            </GridItem>
                            {that.getProgressIndicator()}
                            {getGasErnergFields(
                                window.wizardManager.wizardData.type,
                                window.wizardManager.wizardData.subscriptionType,
                                that,
                                classes,
                                autosuggestProps,
                                autosuggestPropsCateg
                            )}
                        </GridItem>
                    </CardBody>
                </Card>
            </GridContainer>
        );
    }
}

export default withStyles(style)(StepLocConsumData);

function getEEGNFields(state, val) {
    switch (state) {
        case "gas":
            return (
                <CustomInput
                    success={val.state.cod_clc_gnState === "success"}
                    error={val.state.cod_clc_gnState === "error"}
                    labelText={
                        <span>
              Cod CLC GN <small>(obligatoriu)</small>
            </span>
                    }
                    id="cod_clc_gn"
                    formControlProps={{
                        fullWidth: true
                    }}
                    inputProps={{
                        onChange: event => val.change(event, "cod_clc_gn", "cod_clc_gn")
                    }}
                />
            );
        case "electricity":
            return (
                <div>
                    {" "}
                    <CustomInput
                        labelText={<span>Cod NLC</span>}
                        id="cod_nlc"
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            onChange: event => val.change(event, "cod_nlc", "cod_nlc")
                        }}
                    />
                    <CustomInput
                        success={val.state.cod_podState === "success"}
                        error={val.state.cod_podState === "error"}
                        labelText={
                            <span>
                Cod POD <small>(obligatoriu)</small>
              </span>
                        }
                        id="cod_pod"
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            onChange: event => val.change(event, "cod_pod", "mandatory")
                        }}
                    />
                </div>
            );
    }
}

function getGasErnergFields(state, oferType, val, classes, autosuggestProps1, autosuggestPropsCateg) {
    switch (state) {
        case "electricity":
            return getOfferTypeFieldsElec(oferType, val, classes, autosuggestProps1);
        case "gas":
            return getOfferTypeFieldsGas(oferType, val, classes, autosuggestProps1, autosuggestPropsCateg);
    }
}

function getOfferTypeFieldsElec(oferType, val, clases, autosuggestProps1) {
    switch (oferType) {
        case "go":
            return (
                <div>
                    {" "}
                    <CustomInput
                        success={val.state.cantitate_lunara_estimataState === "success"}
                        error={val.state.cantitate_lunara_estimataState === "error"}
                        labelText={
                            <span>
                Cantitate lunara estimata MWh <small>(obligatoriu)</small>
              </span>
                        }
                        id="cantitate_lunara_estimata"
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            onChange: event =>
                                val.change(event, "cantitate_lunara_estimata", "cantitate")
                        }}
                    />{" "}
                </div>
            );
        case "fix":
            return (
                <div>
                    <Autosuggest
                        {...autosuggestProps1}
                        inputProps={{
                            clases,
                            label: "Cantitate lunara estimata MWh (obligatoriu)",
                            placeholder: "Introdu primele 3 cifre",
                            success: val.state.cantitate_lunara_estimataState === "success",
                            error: val.state.cantitate_lunara_estimataState === "error",
                            value: val.state.cantitate_lunara_estimata,
                            onChange: val.handleChange("cantitate_lunara_estimata")
                        }}
                        theme={{
                            container: clases.container,
                            suggestionsContainerOpen: clases.suggestionsContainerOpen,
                            suggestionsList: clases.suggestionsList,
                            suggestion: clases.suggestion
                        }}
                        renderSuggestionsContainer={options => (
                            <Paper {...options.containerProps} square>
                                {options.children}
                            </Paper>
                        )}
                    />
                </div>
            );
    }
}

function getOfferTypeFieldsGas(oferType, val, clases, autosuggestProps1, autosuggestPropsCateg) {
    switch (oferType) {
        case "go":
            return (
                <div>
                    {" "}
                    <CustomInput
                        labelText={<span>Nr. Contract furnizare actual</span>}
                        id="nr_contract_furnizare"
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            onChange: event =>
                                val.change(event, "nr_contract_furnizare", "length", 400)
                        }}
                    />
                    <CustomInput
                        success={val.state.cantitate_anuala_estimataState === "success"}
                        error={val.state.cantitate_anuala_estimataState === "error"}
                        labelText={
                            <span>
                Cantitate anuala estimata MWh <small>(obligatoriu)</small>
              </span>
                        }
                        id="cantitate_anuala_estimata"
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            onChange: event =>
                                val.change(event, "cantitate_anuala_estimata", "cantitate")
                        }}
                    />
                    <Autosuggest
                        {...autosuggestPropsCateg}
                        inputProps={{
                            clases,
                            label: "Categorie consumator (obligatoriu)",
                            placeholder: "Exemplu: C..",
                            success: val.state.categorie_consumatorState === "success",
                            error: val.state.categorie_consumatorState === "error",
                            value: val.state.categorie_consumator,
                            onChange: val.handleChange("categorie_consumator")
                        }}
                        theme={{
                            container: clases.container,
                            suggestionsContainerOpen: clases.suggestionsContainerOpen,
                            suggestionsList: clases.suggestionsList,
                            suggestion: clases.suggestion
                        }}
                        renderSuggestionsContainer={options => (
                            <Paper {...options.containerProps} square>
                                {options.children}
                            </Paper>
                        )}
                    />
                </div>
            );
        case "fix":
            return (
                <div>
                    {" "}
                    <CustomInput
                        labelText={<span>Nr. Contract furnizare actual</span>}
                        id="nr_contract_furnizare"
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            onChange: event =>
                                val.change(event, "nr_contract_furnizare", "length", 400)
                        }}
                    />
                    <Autosuggest
                        {...autosuggestProps1}
                        inputProps={{
                            clases,
                            label: "Cantitate lunara estimata MWh (obligatoriu)",
                            placeholder: "Introdu primele 3 cifre",
                            success: val.state.cantitate_lunara_estimataState === "success",
                            error: val.state.cantitate_lunara_estimataState === "error",
                            value: val.state.cantitate_lunara_estimata,
                            onChange: val.handleChange("cantitate_lunara_estimata")
                        }}
                        theme={{
                            container: clases.container,
                            suggestionsContainerOpen: clases.suggestionsContainerOpen,
                            suggestionsList: clases.suggestionsList,
                            suggestion: clases.suggestion
                        }}
                        renderSuggestionsContainer={options => (
                            <Paper {...options.containerProps} square>
                                {options.children}
                            </Paper>
                        )}
                    />
                </div>
            );
    }
}

function togleAdressFields(tht, val, clases, tat) {
    const autosuggestPropsJudet = {
        renderInputComponent,
        suggestions: tht.state.suggestionsJudet,
        onSuggestionsFetchRequested: tht.handleSuggestionsFetchRequestedJudet,
        onSuggestionsClearRequested: tht.handleSuggestionsClearRequestedJudet,
        getSuggestionValue,
        utils: utils.utils(),
        wizardManger: window.wizardManager,
        state: tht.state,
        renderSuggestion
    };

    const autosuggestPropsLocalitati = {
        renderInputComponent,
        suggestions: tht.state.suggestionsLocalitati,
        onSuggestionsFetchRequested: tht.handleSuggestionsFetchRequestedLocalitati,
        onSuggestionsClearRequested: tht.handleSuggestionsClearRequestedLocalitati,
        getSuggestionValue,
        utils: utils.utils(),
        wizardManger: window.wizardManager,
        state: tht.state,
        renderSuggestion
    };

    const autosuggestPropsStrazi = {
        renderInputComponent,
        suggestions: tht.state.suggestionsStrazi,
        onSuggestionsFetchRequested: tht.handleSuggestionsFetchRequestedStrazi,
        onSuggestionsClearRequested: tht.handleSuggestionsClearRequestedStrazi,
        onSuggestionSelected: tht.onSuggestionSelectedStrazi,
        getSuggestionValue,
        utils: utils.utils(),
        wizardManger: window.wizardManager,
        state: tht.state,
        renderSuggestion
    };

    if (val) {
        return (
            <div>
                <CustomInput
                    labelText={<span>Tara loc consum</span>}
                    id="l_tara"
                    formControlProps={{
                        fullWidth: true
                    }}
                    inputProps={{
                        value: tht.state.l_tara,
                        onChange: event => tht.change(event, "l_tara", "length", 400)
                    }}
                />
                <Autosuggest
                    {...autosuggestPropsJudet}
                    inputProps={{
                        clases,
                        label: "Judet loc consum (obligatoriu)",
                        placeholder: "Introdu primele 3 litere",
                        success: tht.state.l_judetState === "success",
                        error: tht.state.l_judetState === "error",
                        value: tht.state.l_judet,
                        onChange: tht.handleChange("l_judet")
                    }}
                    theme={{
                        container: clases.container,
                        suggestionsContainerOpen: clases.suggestionsContainerOpen,
                        suggestionsList: clases.suggestionsList,
                        suggestion: clases.suggestion
                    }}
                    renderSuggestionsContainer={options => (
                        <Paper {...options.containerProps} square>
                            {options.children}
                        </Paper>
                    )}
                />
                <Autosuggest
                    {...autosuggestPropsLocalitati}
                    inputProps={{
                        clases,
                        disabled: tht.state.unlockLocalitati,
                        label: "Localitate loc consum (obligatoriu)",
                        placeholder: "Introdu primele 3 litere",
                        success: tht.state.l_localitateState === "success",
                        error: tht.state.l_localitateState === "error",
                        value: tht.state.l_localitate,
                        onChange: tht.handleChange("l_localitate")
                    }}
                    theme={{
                        container: clases.container,
                        suggestionsContainerOpen: clases.suggestionsContainerOpen,
                        suggestionsList: clases.suggestionsList,
                        suggestion: clases.suggestion
                    }}
                    renderSuggestionsContainer={options => (
                        <Paper {...options.containerProps} square>
                            {options.children}
                        </Paper>
                    )}
                />

                <Autosuggest
                    {...autosuggestPropsStrazi}
                    inputProps={{
                        clases,
                        disabled: tht.state.unlockStrazi,
                        label: "Strada loc consum (obligatoriu)",
                        placeholder: "Introdu primele 3 litere",
                        success: tht.state.l_stradaState === "success",
                        error: tht.state.l_stradaState === "error",
                        value: tht.state.l_strada,
                        onChange: tht.handleChange("l_strada")
                    }}
                    theme={{
                        container: clases.container,
                        suggestionsContainerOpen: clases.suggestionsContainerOpen,
                        suggestionsList: clases.suggestionsList,
                        suggestion: clases.suggestion
                    }}
                    renderSuggestionsContainer={options => (
                        <Paper {...options.containerProps} square>
                            {options.children}
                        </Paper>
                    )}
                />

                <CustomInput
                    labelText={<span>Nr. loc consum</span>}
                    id="l_nr"
                    formControlProps={{
                        fullWidth: true
                    }}
                    inputProps={{
                        onChange: event => tht.change(event, "l_nr", "length", 400)
                    }}
                />

                <CustomInput
                    labelText={<span>Bloc loc consum</span>}
                    id="l_bloc"
                    formControlProps={{
                        fullWidth: true
                    }}
                    inputProps={{
                        onChange: event => tht.change(event, "l_bloc", "length", 400)
                    }}
                />

                <CustomInput
                    labelText={<span>Scara loc consum</span>}
                    id="l_scara"
                    formControlProps={{
                        fullWidth: true
                    }}
                    inputProps={{
                        onChange: event => tht.change(event, "l_scara", "length", 400)
                    }}
                />

                <CustomInput
                    labelText={<span>Tronson loc consum</span>}
                    id="l_tronson"
                    formControlProps={{
                        fullWidth: true
                    }}
                    inputProps={{
                        onChange: event => tht.change(event, "l_tronson", "length", 400)
                    }}
                />
                <CustomInput
                    labelText={<span>Etaj loc consum</span>}
                    id="l_etaj"
                    formControlProps={{
                        fullWidth: true
                    }}
                    inputProps={{
                        onChange: event => tht.change(event, "l_etaj", "length", 400)
                    }}
                />
                <CustomInput
                    labelText={<span>Apartament loc consum</span>}
                    id="l_apartament"
                    formControlProps={{
                        fullWidth: true
                    }}
                    inputProps={{
                        onChange: event => tht.change(event, "l_apartament", "length", 400)
                    }}
                />
                <CustomInput
                    labelText={<span>Cod postal loc consum</span>}
                    id="l_cod_postal"
                    formControlProps={{
                        fullWidth: true
                    }}
                    inputProps={{
                        value: tht.state.l_cod_postal,
                        onChange: event => tht.change(event, "l_cod_postal", "length", 400)
                    }}
                />
            </div>
        );
    }
    return <div/>;
}
